import React, { useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import logo from '../../assets/images/logo.svg';
import Nav, { NavItem } from 'tbk-components/src/components/Nav';
import { ILink } from '../Button';

export interface IFooterProps extends IBaseElementProps {
	locations?: ILocation[];
	nav?: NavItem[];
	homeUrl?: ILink;
	disclaimer?: string;
	warranty?: string;
	logos?: IImage[];
	copyright?: string;
	instagramUrl?: string;
	facebookUrl?: string;
	youtubeUrl?: string;
	linkedinUrl?: string;
}

export interface ILocation {
	title: string;
	address: string;
}

/**
 * Florz Footer
 *
 * This should be the last component inside the FlorzLandingPage.
 *
 * @icon archive
 * @block
 */
const Footer: React.FC<IFooterProps> = ({
	locations = [],
	nav,
	logos = [],
	homeUrl,
	disclaimer,
	warranty,
	copyright = '© 2024 florz Flooring.',
	instagramUrl,
	facebookUrl,
	youtubeUrl,
	linkedinUrl,
}) => {
	const footer = useRef<HTMLDivElement>(null);
	return (
		<footer ref={footer} className="florz-landing-footer">
			<div className="florz-landing-footer--content-wrapper">
				<div className="florz-landing-footer--top-row">
					<div className="footer-aside">
						{logos?.length ? (
							<div className="footer-logos">
								{logos.map((logo, index) => (
									<Image
										key={index}
										className="footer-logo"
										{...logo}
									></Image>
								))}
							</div>
						) : null}
						<ul className="social-icons">
							{facebookUrl && (
								<li>
									<a
										href={facebookUrl}
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											Facebook
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7 10V14H10V21H14V14H17L18 10H14V8C14 7.73478 14.1054 7.48043 14.2929 7.29289C14.4804 7.10536 14.7348 7 15 7H18V3H15C13.6739 3 12.4021 3.52678 11.4645 4.46447C10.5268 5.40215 10 6.67392 10 8V10H7Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
							)}
							{instagramUrl && (
								<li>
									<a
										href={instagramUrl}
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											Instagram
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M16 4H8C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
							)}
							{linkedinUrl && (
								<li>
									<a
										href={linkedinUrl}
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											LinkedIn
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8 11V16M8 8V8.01M12 16V11M16 16V13C16 12.4696 15.7893 11.9609 15.4142 11.5858C15.0391 11.2107 14.5304 11 14 11C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
							)}
							{youtubeUrl && (
								<li>
									<a
										href={youtubeUrl}
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											YouTube
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17 5H7C4.79086 5 3 6.79086 3 9V15C3 17.2091 4.79086 19 7 19H17C19.2091 19 21 17.2091 21 15V9C21 6.79086 19.2091 5 17 5Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M10 9L15 12L10 15V9Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
							)}
						</ul>
					</div>
					{locations?.length ? (
						<ul className="location-listing">
							{locations.map((location, index) => {
								return (
									<li className="location-block" key={index}>
										<h6>{location.title}</h6>
										<p>{location.address}</p>
									</li>
								);
							})}
						</ul>
					) : null}
					{nav?.length && (
						<Nav
							classNames={['location-listing']}
							dropdownEnabled={false}
							navItems={nav}
						/>
					)}
				</div>
				<div className="florz-landing-footer--bottom-row">
					<div className="footer-back-button">
						{homeUrl && (
							<a
								href={homeUrl.href}
								className="back-button-wrapper"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<span>{homeUrl.label}</span>
							</a>
						)}
					</div>
					<div className="footer-bottom-copy-wrapper">
						<div className="footer-credits-wrapper">
							{copyright && (
								<div className="copyrights">
									<p>{copyright}</p>
								</div>
							)}
							<div className="credit">
								<a
									target="_blank"
									href="https://tbkcreative.com"
									rel="noreferrer noopener"
								>
									<span>
										Web Design & Digital Marketing by tbk
									</span>
								</a>
							</div>
						</div>
						<p className="disclaimer">{disclaimer}</p>
						<p className="disclaimer">{warranty}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
