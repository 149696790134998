import React, { useRef, useEffect } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap, ScrollTrigger } from 'gsap/all';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface IInfoBannerProps extends IBaseElementProps {
	title?: string;
	excerpt?: string;
	image?: IImage;
}
/**
 * InfoBanner
 *
 * This is a two-column banner with an image on the left and text on the right.
 *
 * @icon InfoBanner
 * @block
 */

const InfoBanner: React.FC<IInfoBannerProps> = ({ title, image, excerpt }) => {
	const banner = useRef(null);

	useEffect(() => {
		if (!banner.current) {
			return;
		}
		gsap.registerPlugin(ScrollTrigger);

		const cta = banner.current;

		const madeInEuropeTl = gsap.timeline({
			scrollTrigger: {
				trigger: cta,
				start: '0% center',
			},
		});

		madeInEuropeTl.to('.made-in-europe', {
			duration: 1,
			background:
				'linear-gradient(90deg, #1c1c23 0%, rgb(28 28 35 / 0%) 100%)',
		});

		madeInEuropeTl.to(
			cta.querySelector('.made-in-europe--copy-wrapper h2'),
			{
				duration: 1,
				transform: 'translateX(0px)',
				opacity: 1,
			},
			'-=1',
		);

		madeInEuropeTl.to(
			cta.querySelector('.made-in-europe--copy-wrapper p'),
			{
				duration: 1,
				transform: 'translateX(0px)',
				opacity: 1,
			},
			'-=0.5',
		);
		return () => {
			// cleanup code (optional)
		};
	}, [banner]);
	return (
		<div ref={banner} className="made-in-europe">
			<div className="made-in-europe--content-wrapper">
				<div className="made-in-europe--image-wrapper">
					<Image {...image}></Image>
				</div>
				<div className="made-in-europe--copy-wrapper">
					{title && <h2 className="fade-up">{title}</h2>}
					{excerpt && <p className="fade-up">{excerpt}</p>}
				</div>
			</div>
		</div>
	);
};

export default InfoBanner;
