import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { IButtonProps } from '.';

export interface IButtonListProps extends IBaseElementProps {
	buttons?: IButtonProps[];
	/**
	 * @noUI
	 */
	children?: any; // Expected to be <Button> modules
}

const ButtonList: React.FC<IButtonListProps> = ({ buttons = [], children }) => {
	if (!buttons.length && !children) {
		return null;
	}

	return (
		<div className="button-list">
			{buttons &&
				buttons.map((button, key) => <Button key={key} {...button} />)}
			{children}
		</div>
	);
};

export default ButtonList;
