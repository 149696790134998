import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap, ScrollTrigger } from 'gsap/all';
import Image, { IImage } from 'tbk-components/src/components/Image';
import MaskedDoorImage, { IMaskedDoorImageProps } from '../MaskedDoorImage';

export interface IFormTabsProps extends IBaseElementProps {
	title?: string;
	/**
	 * ID of the Gravity Form to embed
	 */
	gravityFormId?: number;
	/**
	 * ID of field for TabID
	 */
	tabIdFormField?: number;
	tabs?: IFormTab[];
	id?: string;
	children?: any;
}

export interface IFormTab {
	tabLabel?: string;
	heading?: string;
	description?: string;
	image?: IImage;
}

/**
 * FormTabs
 *
 * This component is used to have multiple contact options. They will all share one Gravity Form but will be separated by tabs and email submissions.
 *
 * @icon slides
 * @block
 */
const FormTabs: React.FC<IFormTabsProps> = ({
	id,
	title,
	gravityFormId,
	tabIdFormField,
	children,
	tabs = [],
}) => {
	const FormTabs = useRef(null);
	const firstTabLabel = tabs?.length ? tabs[0].tabLabel : '';
	const [tabDisplay, setTabDisplay] = useState(firstTabLabel);

	const updateTab = (tab) => {
		let input_line = 'input_' + gravityFormId + '_' + tabIdFormField;
		if (document.getElementById(input_line)) {
			(document.getElementById(input_line) as HTMLInputElement).value =
				tab;
		}
		setTabDisplay(tab);
	};

	useEffect(() => {
		if (!FormTabs.current) {
			return;
		}

		updateTab(firstTabLabel);

		const formTab = FormTabs.current;
		const formTabButtons = formTab.querySelectorAll(
			'.form-tabs-container--buttons-wrapper ul li a',
		);

		formTabButtons.forEach((tabButton) => {
			const formTabs = formTab.querySelectorAll(
				'.form-tabs-container--tabs-content-wrapper .inquiry-form-block',
			);
			tabButton.addEventListener('click', (e) => {
				e.preventDefault();
				const currentTabButton = formTab.querySelector(
					'.form-tabs-container--buttons-wrapper ul li a.active',
				);
				const currentTab = formTab.querySelector(
					'.form-tabs-container--tabs-content-wrapper .inquiry-form-block.active',
				);
				const tabTarget = tabButton.getAttribute('data-target');

				if (
					!formTabs ||
					formTabs[tabTarget] == null ||
					currentTab.classList.contains('animating')
				) {
					return;
				}

				currentTabButton.classList.remove('active');
				tabButton.classList.add('active');
				currentTab.classList.add('animating');

				const tabTl = gsap.timeline();

				tabTl
					.fromTo(
						currentTab,
						{ display: 'flex', opacity: 1 },
						{
							duration: 0.15,
							opacity: 0,
							display: 'none',
							onComplete: () => {
								currentTab.classList.remove('active');
								currentTab.classList.remove('animating');
							},
						},
					)
					.fromTo(
						formTabs[tabTarget],
						{
							opacity: 0,
							display: 'none',
						},
						{
							duration: 0.15,
							delay: 0.05,
							opacity: 1,
							display: 'flex',
							onComplete: () => {
								formTabs[tabTarget].classList.add('active');
							},
						},
					);
			});
		});

		return () => {
			// cleanup code (optional)
		};
	}, []);
	return (
		<div id={id} className="inquiry-forms-container" ref={FormTabs}>
			<div className="inquiry-forms-container--content-wrapper">
				<h2 className={tabs?.length < 2 && 'no-gap'}>{title}</h2>
				<div className="form-tabs-container">
					<div className="form-tabs-container--buttons-wrapper">
						{tabs?.length > 1 && (
							<ul>
								{tabs.map((tab, index) => {
									return (
										<li key={index}>
											<a
												href="#"
												data-target={index}
												className={
													tabDisplay === tab.tabLabel
														? ' active'
														: ''
												}
												onClick={() => {
													updateTab(tab.tabLabel);
												}}
											>
												{tab.tabLabel}
											</a>
										</li>
									);
								})}
							</ul>
						)}
					</div>
					<div className="form-tabs-container--tabs-content-wrapper">
						<div className="inquiry-tab-aside">
							{tabs.map((tab, index) => {
								return (
									<div
										key={index}
										className={
											'inquiry-form-block' +
											(tabDisplay === tab.tabLabel
												? ' active'
												: '')
										}
										data-target={index}
									>
										<div className="inquiry-form-block--aside-wrapper">
											<h3>{tab?.heading}</h3>
											<p>{tab?.description}</p>
											{tab?.image && (
												<Image {...tab.image} />
											)}
										</div>
									</div>
								);
							})}
						</div>
						<div className="inquiry-form-gf">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormTabs;
