import React, { useRef, useEffect } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap, ScrollTrigger } from 'gsap/all';
import { IImage } from 'tbk-components/src/components/Image';
import AdvancedImage from '../AdvancedImage';
import Video, { IVideo } from 'tbk-components/src/components/Video';
import cn from 'classnames';
export interface IBannerProps extends IBaseElementProps {
	title?: string;
	/*
	 * This video will be used as the background video.
	 * It is also mandatory to upload a poster image as the video will be disabled for mobile.
	 */
	video?: IVideo;
	image?: IImage;
	/*
	 * Expected to be an oEmbed code that will pass into IVideo
	 */
	videoOembed?: string;
	playButtonLabel?: string;
}
/**
 * Banner
 *
 * This component shows centered text with an optional background video or image wallpaper.
 * There can also be an optional oEmbed video that will open when the play button is clicked.
 *
 * @icon embed-photo
 * @block
 */

const Banner: React.FC<IBannerProps> = ({
	title,
	video,
	image,
	videoOembed,
	playButtonLabel = 'Play Video',
}) => {
	const BannerRef = useRef(null);

	useEffect(() => {
		if (!BannerRef.current) {
			return;
		}
		gsap.registerPlugin(ScrollTrigger);

		const cta = BannerRef.current;
		const introTailTl = gsap.timeline({
			scrollTrigger: {
				trigger: cta,
				start: 'top center',
			},
		});

		introTailTl.to(cta.querySelector('h2'), {
			duration: 1,
			transform: 'translateY(0px)',
			opacity: 1,
		});
		return () => {
			// cleanup code (optional)
		};
	}, [Banner]);
	return (
		<div ref={BannerRef} className="introduction-tail">
			{title && <h2>{title}</h2>}
			{videoOembed && (
				<Video oembed={videoOembed} playLabel={playButtonLabel} />
			)}
			<div className={cn('introduction-overlay', video && 'has-video')}>
				{video && (
					<video className="overlay-video" loop autoPlay muted>
						<source src={video.src} type="video/mp4"></source>
					</video>
				)}
				{image && <AdvancedImage image={image}></AdvancedImage>}
			</div>
		</div>
	);
};

export default Banner;
