import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface IAdvancedImageProps extends IBaseElementProps {
	image?: IImage;
	imageSize?: 'cover' | 'contain';
}

const AdvancedImage: React.FC<IAdvancedImageProps> = ({ image, imageSize = 'cover', className }) => {
	if (!image) {
		return null;
	}

	return (
		<div className={cn(className, 'advanced-image-wrapper', imageSize == 'contain' && `image-size-${imageSize}`)}>
			<Image {...image} />
		</div>
	);
};

export default AdvancedImage;
