import React, { useLayoutEffect, useState, useEffect } from 'react';
import { gsap, ScrollTrigger } from 'gsap/all';
import Button, { ILink } from '../Button';
import AdvancedImage from '../AdvancedImage';
import Image, { IImage } from 'tbk-components/src/components/Image';
import MaskedDoorImage from '../MaskedDoorImage';

// ========== Image Imports ==========
import heroImage from '../../assets/images/hero-splash-image.jpg';
import doorMaskWithEdge from '../../assets/images/florz_floor_door_mask_with_edge.png';
import doorMaskWithEdgeFlipped from '../../assets/images/florz_floor_door_mask_with_edge-flipped.png';
import doorMask from '../../assets/images/florz_floor_door_mask.png';
import iconOak from '../../assets/images/icon-oak.svg';
import icon35YearWarranty from '../../assets/images/icon-35-year-warranty.svg';
import iconMadeInEurope from '../../assets/images/icon-made-in-europe.svg';
import herringBonePattern from '../../assets/images/bg-herringbone-pattern.svg';
import chevronPattern from '../../assets/images/bg-chevron-pattern.svg';
import renaissanceBackdrop from '../../assets/images/renaissance-of-sculptured-design.jpg';
import herringboneShowcase from '../../assets/images/herringbone-collection-showcase.jpg';
import chevronShowcase from '../../assets/images/chevron-collection-showcase.jpg';
import canyonSwatch from '../../assets/images/swatch-herringbone-canyon.jpg';
import citadelSwatch from '../../assets/images/swatch-herringbone-citadel.jpg';
import cavallinaSwatch from '../../assets/images/swatch-herringbone-cavallina.jpg';
import vallettaSwatch from '../../assets/images/swatch-herringbone-valletta.jpg';
import arabelleSwatch from '../../assets/images/swatch-herringbone-arabelle.jpg';
import estateSwatch from '../../assets/images/swatch-herringbone-estate.jpg';
import snowPeakSwatch from '../../assets/images/swatch-herringbone-snow-peak.jpg';
import herringboneCanyon from '../../assets/images/herringbone-canyon.jpg';
import herringboneCitadel from '../../assets/images/herringbone-citadel.jpg';
import herringboneCavallina from '../../assets/images/herringbone-cavallina.jpg';
import herringboneValletta from '../../assets/images/herringbone-valletta.jpg';
import herringboneArabelle from '../../assets/images/herringbone-arabelle.jpg';
import herringboneEstate from '../../assets/images/herringbone-estate.jpg';
import herringboneSnowPeak from '../../assets/images/herringbone-snow-peak.jpg';
import chevronCanyonSwatch from '../../assets/images/swatch-chevron-canyon.jpg';
import chevronCitadelSwatch from '../../assets/images/swatch-chevron-citadel.jpg';
import chevronEstateSwatch from '../../assets/images/swatch-chevron-estate.jpg';
import madeInEurope from '../../assets/images/made-in-europe.png';
import introWallpaper from '../../assets/images/introduction-wallpaper.jpg';
import logo from '../../assets/images/logo.svg';
import florzFamilyBanner2 from '../../assets/images/florz-family-banner-2.jpg';
import florzFamilyBanner1 from '../../assets/images/florz-family-banner-1-new.jpg';
import altBubble1 from '../../assets/images/alt-bubble-1.jpg';
import altBubble2 from '../../assets/images/alt-bubble-2.jpg';
import altBubble3 from '../../assets/images/alt-bubble-3.jpg';
import straightPlankIcon from '../../assets/images/straight-plank-icon.svg';
import chevronIcon from '../../assets/images/chevron-icon.svg';
import herringboneIcon from '../../assets/images/herringbone-icon.svg';
import chevronCanyon from '../../assets/images/chevron-canyon.jpg';
import chevronCitadel from '../../assets/images/chevron-citadel.jpg';
import chevronEstate from '../../assets/images/chevron-estate.jpg';

// ================= Video Asset Imports =================
import videoClouds from '../../assets/videos/clouds.mp4';
import videoCloudsWebm from '../../assets/videos/clouds.webm';
import videoTrees from '../../assets/videos/trees.mp4';
import videoTreesWebm from '../../assets/videos/trees.webm';

const herringboneIconImage = {
	src: herringboneIcon,
	height: 24,
	width: 24,
};

const chevronIconImage = {
	src: chevronIcon,
	height: 24,
	width: 24,
};

const straightPlankIconImage = {
	src: straightPlankIcon,
	height: 24,
	width: 24,
};

const logoImage = {
	src: logo,
	height: 36,
	width: 200,
};

const florzFamilyBanner2Image = {
	src: florzFamilyBanner2,
	height: 902,
	width: 1353,
};

const florzFamilyBanner1Image = {
	src: florzFamilyBanner1,
	height: 576,
	width: 576,
};

const altBubble1Image = {
	src: altBubble1,
	height: 500,
	width: 500,
};

const altBubble2Image = {
	src: altBubble2,
	height: 500,
	width: 500,
};

const altBubble3Image = {
	src: altBubble3,
	height: 500,
	width: 500,
};

const heroSplashImage = {
	src: heroImage,
	height: 1080,
	width: 1920,
};

const renaissanceBackdropImage = {
	src: renaissanceBackdrop,
	height: 1920,
	width: 806,
};

const chevronCanyonSwatchImage = {
	src: chevronCanyonSwatch,
	height: 80,
	width: 80,
};
const chevronCitadelSwatchImage = {
	src: chevronCitadelSwatch,
	height: 80,
	width: 80,
};
const chevronEstateSwatchImage = {
	src: chevronEstateSwatch,
	height: 80,
	width: 80,
};

const canyonSwatchImage = {
	src: canyonSwatch,
	height: 80,
	width: 80,
};

const citadelSwatchImage = {
	src: citadelSwatch,
	height: 80,
	width: 80,
};
const cavallinaSwatchImage = {
	src: cavallinaSwatch,
	height: 80,
	width: 80,
};
const vallettaSwatchImage = {
	src: vallettaSwatch,
	height: 80,
	width: 80,
};
const arabelleSwatchImage = {
	src: arabelleSwatch,
	height: 80,
	width: 80,
};
const estateSwatchImage = {
	src: estateSwatch,
	height: 80,
	width: 80,
};
const snowPeakSwatchImage = {
	src: snowPeakSwatch,
	height: 80,
	width: 80,
};

const herringboneShowcaseImage = {
	src: herringboneShowcase,
	height: 811,
	width: 1147,
	alt: 'Estate Style from Florz Herringbone Collection',
};

const herringboneCanyonImage = {
	src: herringboneCanyon,
	height: 811,
	width: 1147,
};
const herringboneCitadelImage = {
	src: herringboneCitadel,
	height: 811,
	width: 1147,
};
const herringboneCavallinaImage = {
	src: herringboneCavallina,
	height: 811,
	width: 1147,
};
const herringboneVallettaImage = {
	src: herringboneValletta,
	height: 811,
	width: 1147,
};
const herringboneArabelleImage = {
	src: herringboneArabelle,
	height: 811,
	width: 1147,
};
const herringboneEstateImage = {
	src: herringboneEstate,
	height: 811,
	width: 1147,
};
const herringboneSnowPeakImage = {
	src: herringboneSnowPeak,
	height: 811,
	width: 1147,
};

const chevronCanyonImage = {
	src: chevronCanyon,
	height: 811,
	width: 1147,
};
const chevronCitadelImage = {
	src: chevronCitadel,
	height: 811,
	width: 1147,
};
const chevronEstateImage = {
	src: chevronEstate,
	height: 811,
	width: 1147,
};

const chevronShowcaseImage = {
	src: chevronShowcase,
	height: 579,
	width: 819,
	alt: 'Canyon Style from Florz Chevron Collection',
};

const iconOakImage = {
	src: iconOak,
	height: 48,
	width: 48,
};

const iconMadeInEuropeImage = {
	src: iconMadeInEurope,
	height: 48,
	width: 48,
};

const icon35YearWarrantyImage = {
	src: icon35YearWarranty,
	height: 48,
	width: 48,
};

const madeInEuropeImage = {
	src: madeInEurope,
	height: 314,
	width: 524,
};

// ========== End Image Imports ==========

/**
 * @usePostData
 * @post_type page
 */

// Per scope and request, these props are strictly for language switching purposes.
// Just strings and URLs.
// Since this is also static content, any repeated Interface will simply have manual keys (firstItem, secondItem, thirdItem. canyonCaption, citadelCaption, etc.)

export interface IContentTemplateProps {
	global: {
		homeBackButton: ILink;
		captionPretext: string;
		availablePatterns: string;
		swatches: {
			/**
			 * Only references up to 7 highlights
			 */
			herringbone: IStaticSwatch[];
			/**
			 * Only references up to 3 highlights
			 */
			chevron: IStaticSwatch[];
		};
	};
	hero: {
		eyebrow: string;
		title: {
			top: string;
			middle: string;
			bottom: string;
		};
		excerpt: string;
		button: ILink;
	};
	introduction: {
		title: string;
		excerpt: string;
		/**
		 * Only references up to 3 highlights
		 */
		highlights: string[];
		firstImageCaption: IMaskedDoorCaptions;
		secondImageCaption: IMaskedDoorCaptions;
	};
	introductionTailTitle: string;
	productsIntro: {
		title: {
			pretext: string;
			italicizedText: string;
		};
		subtitle: string;
		excerpt: string;
	};
	/**
	 * Only references up to 7 swatches
	 */
	productCarouselHerringbone: IProductCarouselProps;
	/**
	 * Only references up to 3 swatches
	 */
	productCarouselChevron: IProductCarouselProps;
	madeInEurope: {
		title: string;
		excerpt: string;
	};
	alternatingContent: {
		title: string;
		/**
		 * Only references up to 3 rows
		 */
		rows: {
			title: string;
			excerpt: string;
		}[];
	};
	introduction2: {
		title: string;
		excerpt: string;
		/**
		 * Only references up to 3 highlights
		 */
		highlights: {
			stat: string;
			label: string;
		}[];
	};
	inquiries: {
		title: string;
		tabs: {
			label: string;
			title: string;
			excerpt: string;
			//Form props are TBD depending if we use GF.
		};
	};
	footer: {
		disclaimer: string;
		disclaimer1: string;
		copyright: string;
		privacyPolicy: ILink;
		termsConditions: ILink;
		canadaOffice: {
			title: string;
			address: string;
		};
		canadaWestOffice: {
			title: string;
			address: string;
		};
		usOffice: {
			title: string;
			address: string;
		};
		tbkPretext: string;
	};
	form: {
		firstName: string;
		lastName: string;
		email: string;
		message: string;
		company: string;
		submit: string;
		postal: string;
	};
}
export interface IMaskedDoorCaptions {
	label: string;
	href: string;
	swatchKey: number;
}

//This is only partially complete. A full ISwatch[] would include the associated carousel image and thumbnail. Then wired into a proper forEach on the Carousel render.
export interface IStaticSwatch {
	name: string;
	caption: string;
}

export interface IProductCarouselProps {
	title: string;
	excerpt: string;
	swatches: IStaticSwatch[];
	accordionLabel: string;
	measurements: {
		measurement: string;
		spec: string;
	}[];
	patternLabel1: string;
	patternLabel2: string;
	pdfUrl: string;
	specSheetLabel: string;
}

export interface ILandingPageProps {
	/**
	 * @noUI
	 */
	content: {
		en: IContentTemplateProps;
		fr: IContentTemplateProps;
	};
	lang: 'en' | 'fr';
	/**
	 * ID of the Gravity Form to embed
	 */
	gravityFormId?: number;
	/**
	 * ID of field for TabID
	 */
	tabIdFormField?: number;
	/**
	 * English link
	 */
	enLink?: string;
	/**
	 * French link
	 */
	frLink?: string;
	children?;
}

const enValueTemplate = {
	global: {
		homeBackButton: {
			label: 'florz Home',
			href: 'https://florzflooring.com/',
		},
		captionPretext: 'Shown:',
		availablePatterns: 'Available Patterns',
	},
	hero: {
		eyebrow: 'florz Presents',
		title: {
			top: 'The',
			middle: 'Florz',
			bottom: 'Collection',
		},
		excerpt: 'Herringbone | Chevron | Straight Plank Hardwood Flooring',
		button: {
			label: 'Now Available',
			href: 'inquiry-forms',
		},
	},
	introduction: {
		title: 'Introducing our new Florz collection',
		excerpt:
			"When you're looking for flooring with the wow factor, the Florz Collection from florz Flooring is sure to impress. The new collection is simultaneously stylish and timeless—just as suitable in a contemporary condo as it is in a sprawling country estate.",
		highlights: ['European Oak', 'Made in Europe', '35-Year Warranty*'],
		firstImageCaption: {
			label: 'Florz Chevron Collection',
			href: 'Florz-chevron-collection',
			swatchKey: 0,
		},
		secondImageCaption: {
			label: 'Florz Herringbone Collection',
			href: 'Florz-herringbone-collection',
			swatchKey: 5,
		},
	},
	introductionTailTitle: 'A Renaissance of Sculptured Design',
	productsIntro: {
		title: {
			pretext: 'Discover a',
			italicizedText: 'Life Extraordinary',
		},
		subtitle: "YOUR FLOORS SHOULD BE AS ARTISTIC AS WHAT'S ON YOUR WALLS",
		excerpt:
			'florz Florz Flooring comes in timeless patterns: Herringbone and Chevron, as well as straight plank options.',
	},
	productCarouselHerringbone: {
		title: 'Florz Herringbone',
		excerpt:
			'The Herringbone design comes in seven beautiful choices of stain that work well with any home décor. Cool-toned Arabelle and Cavallina stains contrast with the warmer tones of the Estate and Canyon options.',
		specSheetLabel: 'View SpecSheet',
		pdfUrl: 'https://florzflooring.com/Florz/app/uploads/2023/04/Sell-Sheet-Florz-Herringbone.pdf',
		swatches: [
			{
				name: 'Canyon',
				caption: 'Canyon Style from Florz Herringbone Collection',
			},
			{
				name: 'Citadel',
				caption: 'Citadel Style from Florz Herringbone Collection',
			},
			{
				name: 'Cavallina',
				caption: 'Cavallina Style from Florz Herringbone Collection',
			},
			{
				name: 'Valletta',
				caption: 'Valletta Style from Florz Herringbone Collection',
			},
			{
				name: 'Arabelle',
				caption: 'Arabelle Style from Florz Herringbone Collection',
			},
			{
				name: 'Estate',
				caption: 'Estate Style from Florz Herringbone Collection',
			},
			{
				name: 'Snow Peak',
				caption: 'Snow Peak Style from Florz Herringbone Collection',
			},
		],
		patternLabel1: 'Herringbone',
		patternLabel2: 'Straight Plank',
		accordionLabel: 'Product specifications',
		measurements: [
			{
				measurement: 'Species',
				spec: 'European oak',
			},
			{
				measurement: 'Structure',
				spec: 'Engineered 3-layer solidcore',
			},
			{
				measurement: 'Surface Layer',
				spec: '2.5 MM wood face layer',
			},
			{
				measurement: 'Width',
				spec: '5.11" (Citadel is 4.33")',
			},
			{
				measurement: 'Thickness',
				spec: '9/16"',
			},
			{
				measurement: 'Length',
				spec: '28.5"',
			},
			{
				measurement: 'Edge Detail',
				spec: '4-sided micro-beveled',
			},
			{
				measurement: 'Surface Texture',
				spec: 'Light wirebrushed',
			},

			{
				measurement: 'Gloss Level',
				spec: 'Low degree luster level',
			},
			{
				measurement: 'Variation Level',
				spec: '3 - strong',
			},
			{
				measurement: 'Finish',
				spec: 'UV cured polyurethane finish with aluminum oxide',
			},
			{
				measurement: 'Installation',
				spec: 'Click, stapled, glued down or floated',
			},
			{
				measurement: 'Suitable Location',
				spec: 'On, above or below grade',
			},
			{
				measurement: 'Certification',
				spec: 'Floorscore',
			},
			{
				measurement: 'Radiant Heat',
				spec: 'Suitable for use with hydronic systems',
			},
			{
				measurement: 'Warranty',
				spec: 'Limited 35-year residential & lifetime structural',
			},
			{
				measurement: 'Price Range ($ - $$$$)',
				spec: '$$$$',
			},
		],
	},
	productCarouselChevron: {
		title: 'Florz chevron',
		excerpt:
			'Our Chevron design adds easy elegance to any room. These artisanal engineered hardwood floors have beautiful graining, adding rich dimension and creating interesting visual appeal. Florz Chevron is available in three versatile stain options: the warm Canyon stain, or the Citadel cool tone.',
		specSheetLabel: 'View SpecSheet',
		pdfUrl: 'https://florzflooring.com/Florz/app/uploads/2023/04/Sell-Sheet-Florz-Chevron.pdf',
		swatches: [
			{
				name: 'Canyon',
				caption: 'Canyon Style from Florz Chevron Collection',
			},
			{
				name: 'Citadel',
				caption: 'Citadel Style from Florz Chevron Collection',
			},
			{
				name: 'Estate',
				caption: 'Estate Style from Florz Chevron Collection',
			},
		],
		patternLabel1: 'Chevron',
		patternLabel2: 'Straight Plank',
		accordionLabel: 'Product specifications',
		measurements: [
			{
				measurement: 'Species',
				spec: 'European oak',
			},
			{
				measurement: 'Structure',
				spec: 'Engineered 3-layer solidcore',
			},
			{
				measurement: 'Surface Layer',
				spec: '2.5 MM wood face layer',
			},
			{
				measurement: 'Width',
				spec: '5.11"',
			},
			{
				measurement: 'Thickness',
				spec: '9/16"',
			},
			{
				measurement: 'Length',
				spec: '28.5"',
			},
			{
				measurement: 'Edge Detail',
				spec: 'Micro-beveled edges',
			},
			{
				measurement: 'Surface Texture',
				spec: 'Light wirebrushed',
			},

			{
				measurement: 'Gloss Level',
				spec: 'Low degree luster level',
			},
			{
				measurement: 'Variation Level',
				spec: '3 - strong',
			},
			{
				measurement: 'Finish',
				spec: 'UV cured polyurethane finish with aluminum oxide',
			},
			{
				measurement: 'Installation',
				spec: 'T&G, stapled, glued down or floated',
			},
			{
				measurement: 'Suitable Location',
				spec: 'On, above or below grade',
			},
			{
				measurement: 'Certification',
				spec: 'Floorscore',
			},
			{
				measurement: 'Radiant Heat',
				spec: 'Suitable for use with hydronic systems',
			},
			{
				measurement: 'Warranty',
				spec: 'Limited 35-year residential & lifetime structural',
			},
			{
				measurement: 'Price Range ($ - $$$$)',
				spec: '$$$$',
			},
		],
	},
	madeInEurope: {
		title: 'Florz is Made in Europe',
		excerpt:
			"Inspired by the aristocracy of 15th and 16th century France, the Florz Collection incorporates Herringbone and Chevron designs, which were—and are—seen as a status symbol. florz Flooring uses European Oak to update this classic design for modern living. It's a stylish way to add luxury to your home.",
	},
	alternatingContent: {
		title: 'Engineered for Real Life',
		rows: [
			{
				title: 'Design',
				excerpt:
					"Choose the right Florz flooring for any space: anything from a welcoming foyer to a comfortable living room to gorgeous whole home hardwood. It's like a piece of art in your own home.",
			},
			{
				title: 'Install',
				excerpt:
					'We recommend an experienced installer lovingly on your behalf—so you can experience the beauty of artisanal flooring sooner.',
			},
			{
				title: 'Enjoy',
				excerpt:
					"florz's Florz floors are meant to last a lifetime, supporting your every step. Your new hardwood flooring is the foundation of a happy household. So go ahead, enjoy your newfound art underfoot. It's as beautiful as those you love most.",
			},
		],
	},
	introduction2: {
		title: 'The florz Family',
		excerpt:
			"florz Flooring is Canada's premier multi-surface flooring company—with a reputation for high-quality products and expert craftsmanship. The Florz Collection is the latest example of our commitment to homeowners and designers. We strive to provide highly coveted designs, value, and engineered hardwood expertise.",
		highlights: [
			{
				stat: '1200+',
				label: 'Flooring Retailers',
			},
			{
				stat: '300M+',
				label: 'Sq. feet installed',
			},
			{
				stat: '100+',
				label: 'Employees serving North America',
			},
		],
	},
	inquiries: {
		title: 'Inquire About Florz',
		tabs: [
			{
				label: 'Designers',
				title: "Designers' Favourite Flooring",
				excerpt:
					"Made for designers like you with refined taste, florz Flooring has become a fan favourite. Simply fill out this form to get trade pricing and samples of our flooring. We'll show you what's in stock and help you understand order times.",
			},
			{
				label: 'Flooring Retailers',
				title: 'Florz Flooring for Flooring Retailers',
				excerpt:
					'Are you a flooring retailer looking to add a new line of revenue to your business? florz Flooring can help you with dealer pricing and sourcing. Simply fill out this form to get started.',
			},
			{
				label: 'Consumers',
				title: 'Artisanal Flooring for Your Home',
				excerpt:
					'For homeowners who crave style and sophistication, the Florz Collection adds rich depth to any room. Although we do not sell direct to consumers, we invite you to contact us with questions. florz Flooring can direct you where to buy our beautiful flooring.',
			},
		],
	},
	footer: {
		disclaimer:
			'For illustrative purposes only. Each product contains its own distinct character & colour variation. Speak to your retailer or florz representative to ensure product satisfaction before purchase. Visit florzFlooring.com for more information on warranties, installation, care and maintenance.',
		disclaimer1:
			'*Limited 35-year residential & lifetime structural warranty',
		copyright: '© 2023 florz Flooring.',
		privacyPolicy: {
			label: 'Privacy Policy',
			href: '#',
		},
		termsConditions: {
			label: 'Terms & Conditions',
			href: '#',
		},
		tbkPretext: 'Web Design & Digital Marketing by tbk',
		canadaOffice: {
			title: 'Canada Head Office',
			address: '6745 Financial Drive Mississauga, Ontario  L5N 7J7',
		},
		canadaWestOffice: {
			title: 'Canada West',
			address: '5375 - 50 St. SE Bay #3, Calgary, Alberta  T2C 3W1',
		},
		usOffice: {
			title: 'U.S.',
			address:
				'2701 E. Hwy 121 Building 5, Suite 500 Lewisville, Texas, 75056',
		},
	},
	form: {
		name: 'Name',
		firstName: 'First Name',
		lastName: 'Last Name',
		email: 'Email',
		postal: 'Postal',
		company: 'Company',
		message: 'Message',
		submit: 'Send',
	},
};

const frValueTemplate = {
	global: {
		homeBackButton: {
			label: 'Accueil florz',
			href: 'https://fr.florzflooring.com/',
		},
		captionPretext: 'Photo :',
		availablePatterns: 'Motifs offerts ',
	},
	hero: {
		eyebrow: 'florz présente',
		title: {
			top: 'La Collection',
			middle: 'Florz',
			bottom: '',
		},
		excerpt: 'Herringbone | Chevrons | Planches',
		button: {
			label: 'Maintenant en vente',
			href: 'inquiry-forms',
		},
	},
	introduction: {
		title: 'Voici notre nouvelle collection Florz',
		excerpt:
			'Si vous recherchez la perle rare, optez pour la collection Florz de florz qui impressionne à tout coup. Cette nouvelle collection suit les dernières tendances tout en offrant un style intemporel; elle convient aux logements contemporains comme aux propriétés de campagne.',
		highlights: [
			'Chêne européen',
			'Fabriqué en Europe',
			'Garantie de 35 ans*',
		],
		firstImageCaption: {
			label: 'Collection Florz à chevrons',
			href: 'collection-Florz-a-chevrons',
			swatchKey: 0,
		},
		secondImageCaption: {
			label: 'Collection Florz à herringbone',
			href: 'collection-Florz-a-herringbone',
			swatchKey: 5,
		},
	},
	introductionTailTitle: 'La renaissance de la sculpture du bois',
	productsIntro: {
		title: {
			pretext: 'Découvrez Un Style',
			italicizedText: 'Des Plus Exceptionnels',
		},
		subtitle:
			'Votre côté artistique devrait se refléter tant sur vos planchers que sur vos murs',
		excerpt:
			'Les planchers Florz de florz sont offerts en motifs intemporels : à herringbone, à chevrons et en planches droites.',
	},
	productCarouselHerringbone: {
		title: 'Florz à herringbone',
		excerpt:
			"Le motif à herringbone est offert en sept magnifiques couleurs qui s'agencent à merveille avec tous les styles. Les tons frais, Arabelle et Cavallina, contrastent avec les tons chaleureux, Estate et Canyon.",
		specSheetLabel: 'Voir Spécifications Techniques',
		pdfUrl: 'https://florzflooring.com/Florz/app/uploads/2023/04/Sell-Sheet-Florz-Herringbone-fr.pdf',
		swatches: [
			{
				name: 'Canyon',
				caption: 'Collection Florz à herringbone, style Canyon',
			},
			{
				name: 'Citadel',
				caption: 'Collection Florz à herringbone, style Citadel',
			},
			{
				name: 'Cavallina',
				caption: 'Collection Florz à herringbone, style Cavallina',
			},
			{
				name: 'Valletta',
				caption: 'Collection Florz à herringbone, style Valletta',
			},
			{
				name: 'Arabelle',
				caption: 'Collection Florz à herringbone, style Arabelle',
			},
			{
				name: 'Estate',
				caption: 'Collection Florz à herringbone, style Estate',
			},
			{
				name: 'Snow Peak',
				caption: 'Collection Florz à herringbone, style Snow Peak',
			},
		],
		patternLabel1: 'Herringbone',
		patternLabel2: 'Planches',
		accordionLabel: 'Spécifications Du Produit',
		measurements: [
			{
				measurement: 'Essences',
				spec: 'Chêne européen',
			},
			{
				measurement: 'Structure',
				spec: "Âme Solid-Core d'ingénierie 3 couches",
			},
			{
				measurement: "Couche d'usure",
				spec: "Couche d'usure en bois de 2,5 mm",
			},
			{
				measurement: 'Largeur',
				spec: '5,11 po (Citadel : 4,33 po)',
			},
			{
				measurement: 'Épaisseur',
				spec: '9/16 po',
			},
			{
				measurement: 'Longueur',
				spec: '28,5 po',
			},
			{
				measurement: 'Détail des Bords',
				spec: '4 côtés microbiseautés',
			},
			{
				measurement: 'Texture de la surface',
				spec: 'Brossage léger',
			},

			{
				measurement: 'Degré de lustre',
				spec: 'Faible',
			},
			{
				measurement: 'Niveau de variation',
				spec: '3 - élevé',
			},
			{
				measurement: 'Fini',
				spec: "Polyuréthane séché aux UV avec oxyde d'aluminium",
			},
			{
				measurement: 'Installation',
				spec: 'À clic, à agrafes, collé ou flottant',
			},
			{
				measurement: 'Emplacement approprié',
				spec: 'Au niveau du sol, au-dessus du sol, au-dessous du sol',
			},
			{
				measurement: 'Certification',
				spec: 'Floorscore',
			},
			{
				measurement: 'Chauffage à rayonnement',
				spec: 'Convient à une utilisation avec système hydronique',
			},
			{
				measurement: 'Garantie',
				spec: "35 ans limitée contre l'usure complète, à vie sur la structure",
			},
			{
				measurement: 'Fourchette de prix ($ - $$$$)',
				spec: '$$$$',
			},
		],
	},
	productCarouselChevron: {
		title: 'Florz à chevrons',
		excerpt:
			"Notre motif à chevrons ajoute une touche d'élégance naturelle à n'importe quelle pièce. Le magnifique grain du bois franc d'ingénierie ressort sur ces planchers artisanaux, ce qui leur donne une texture riche et crée un aspect des plus attrayants. Le motif à chevrons est offert en trois couleurs polyvalentes : Canyon, un ton chaleureux, ainsi que Citadel, un ton froid.",
		specSheetLabel: 'Voir Spécifications Techniques',
		pdfUrl: 'https://florzflooring.com/Florz/app/uploads/2023/04/Sell-Sheet-Florz-Chevron-fr.pdf',
		swatches: [
			{
				name: 'Canyon',
				caption: 'Collection Florz à chevrons, style Canyon',
			},
			{
				name: 'Citadel',
				caption: 'Collection Florz à chevrons, style Citadel',
			},
			{
				name: 'Estate',
				caption: 'Collection Florz à chevrons, style Estate',
			},
		],
		patternLabel1: 'Chevrons',
		patternLabel2: 'Planches',
		accordionLabel: 'Spécifications Du Produit',
		measurements: [
			{
				measurement: 'Essences',
				spec: 'Chêne européen',
			},
			{
				measurement: 'Structure',
				spec: "Âme Solid-Core d'ingénierie 3 couches",
			},
			{
				measurement: "Couche d'usure",
				spec: "Couche d'usure en bois de 2,5 mm",
			},
			{
				measurement: 'Largeur',
				spec: '5,11 po',
			},
			{
				measurement: 'Épaisseur',
				spec: '9/16 po',
			},
			{
				measurement: 'Longueur',
				spec: '28,5 po',
			},
			{
				measurement: 'Détail des Bords',
				spec: 'Bords microbiseautés',
			},
			{
				measurement: 'Texture de la surface',
				spec: 'Brossage léger',
			},
			{
				measurement: 'Degré de lustre',
				spec: 'Faible',
			},
			{
				measurement: 'Degré de variation',
				spec: '3 - Élevé',
			},
			{
				measurement: 'Fini',
				spec: "Polyuréthane séché aux UV avec oxyde d'aluminium",
			},
			{
				measurement: 'Installation',
				spec: 'Rainure et languette (à agrafe, collé ou flottant)',
			},
			{
				measurement: 'Emplacement approprié',
				spec: 'Au niveau du sol, au-dessus du sol, au-dessous du sol',
			},
			{
				measurement: 'Certification',
				spec: 'Floorscore',
			},
			{
				measurement: 'Chauffage à rayonnement',
				spec: 'Convient à une utilisation avec système hydronique',
			},
			{
				measurement: 'Garantie',
				spec: "35 ans limitée contre l'usure complète, à vie sur la structure",
			},
			{
				measurement: 'Fourchette de prix ($ - $$$$)',
				spec: '$$$$',
			},
		],
	},
	madeInEurope: {
		title: 'La collection Atistry est fabriquée en Europe',
		excerpt:
			"Inspirée de la noblesse française des 15e et 16e siècles, la collection Florz intègre les motifs à herringbone et à chevrons considérés comme un symbole de prestige, à cette époque comme de nos jours. florz a opté pour le chêne européen afin d'adapter ce style classique au mode de vie moderne. Ajoutez une touche d'élégance luxueuse à votre demeure.",
	},
	alternatingContent: {
		title: 'Une collection conçue pour la vraie vie',
		rows: [
			{
				title: 'Conception',
				excerpt:
					"Que ce soit pour créer un hall chaleureux, aménager un salon confortable ou donner un aspect somptueux à toute la maison, vous trouverez dans la collection Florz le plancher de bois franc idéal pour transformer votre demeure en véritable œuvre d'art.",
			},
			{
				title: 'Installation',
				excerpt:
					'Nous pouvons vous recommander un installateur chevronné qui posera votre plancher avec soins pour que vous puissiez profiter de sa beauté artisanale plus rapidement.',
			},
			{
				title: 'Utilisation',
				excerpt:
					"Les planchers Florz de florz sont conçus pour durer; ils vous accompagneront tout au long de votre vie, et constitueront la pierre d'assise d'un foyer harmonieux. Ressentez la qualité sous vos pieds, et chérissez cette œuvre d'art.",
			},
		],
	},
	introduction2: {
		title: 'La famille florz',
		excerpt:
			"Chez florz Flooring, nous sommes fiers d'être le principal distributeur de revêtements de plancher multisurfaces au Canada, et ce, grâce à notre excellente réputation, à nos produits de grande qualité et à notre savoir faire. La collection Florz est le parfait exemple de notre dévouement envers les propriétaires et les designers. Nous vous offrons des styles convoités ayant une valeur ajoutée, ainsi qu'une grande expertise en matière de bois franc d'ingénierie.",
		highlights: [
			{
				stat: '1 200 +',
				label: 'détaillants',
			},
			{
				stat: '300 M +',
				label: 'de pieds carrés posés',
			},
			{
				stat: '100 +',
				label: 'employés pour vous servir en Amérique du Nord',
			},
		],
	},
	inquiries: {
		title: 'Renseignez-vous sur Florz',
		tabs: [
			{
				label: 'Designers',
				title: 'Le plancher favori des designers',
				excerpt:
					'Les planchers florz sont adorés des designers aux goûts raffinés. Il suffit de remplir ce formulaire pour profiter de prix avantageux et recevoir des échantillons. Nous vous montrerons les produits en stock et vous aiderons à comprendre les délais de commande.',
			},
			{
				label: 'Détaillants',
				title: 'Les planchers Florz pour les détaillants',
				excerpt:
					"Vous êtes un détaillant de planchers et vous cherchez à ajouter une nouvelle source de revenus à votre commerce? florz vous offre des prix et un approvisionnement propres aux détaillants. Il suffit de remplir ce formulaire pour vous lancer dans l'aventure florz.",
			},
			{
				label: 'Consommateurs',
				title: 'Des planchers artisanaux pour votre demeure',
				excerpt:
					"Si vous êtes un propriétaire à la recherche d'un style élégant et sophistiqué, nous vous conseillons la collection Florz pour donner richesse et profondeur à votre pièce. Nous ne vendons pas directement aux consommateurs, mais nous vous encourageons à communiquer avec nous si vous avez des questions. Nous serons heureux de vous aider à trouver un endroit où acheter les magnifiques planchers florz.",
			},
		],
	},
	footer: {
		disclaimer:
			"À titre indicatif seulement. Chaque produit possède des caractéristiques et des variations de couleur uniques. Communiquez avec votre détaillant ou votre représentant florz pour vous assurer que vous êtes satisfait du produit avant l'achat. Visitez le site florzFlooring.com pour consulter les garanties et les guides d'entretien",
		disclaimer1:
			'*Garantie structurelle résidentielle et à vie limitée de 35 ans',
		copyright: '© 2023 florz Flooring.',
		privacyPolicy: {
			label: 'Politique de confidentialité',
			href: '#',
		},
		termsConditions: {
			label: 'Conditions générales',
			href: '#',
		},
		tbkPretext: 'Conception Web et marketing numérique',
		canadaOffice: {
			title: 'Siège social au Canada',
			address: '6745 Financial Drive Mississauga, Ontario  L5N 7J7',
		},
		canadaWestOffice: {
			title: 'Ouest du Canada',
			address: '5375 - 50 St. SE Bay #3, Calgary, Alberta  T2C 3W1',
		},
		usOffice: {
			title: 'ÉTATS-UNIS',
			address:
				'2701 E. Hwy 121 Building 5, Suite 500 Lewisville, Texas, 75056',
		},
	},
	form: {
		name: 'Nom',
		firstName: 'Prénom',
		lastName: 'Nom de famille',
		email: 'Adresse courriel',
		postal: 'Code postal',
		company: "Nom de l'entreprise",
		message: 'Message',
		submit: 'Envoyer',
	},
};

const contentValues = {
	en: enValueTemplate,
	fr: frValueTemplate,
};
/**
 * Landing Page for Florz Collection
 *
 * This is an older static version of the Landing Page. Use FlorzLandingPage for a more modular experience.
 *
 * @param name
 * @param greeting
 * @param links
 * @constructor
 * @icon admin-site
 * @block
 */
const LandingPage: React.FC<ILandingPageProps> = ({
	//props here
	content = contentValues,
	lang,
	gravityFormId,
	tabIdFormField,
	enLink,
	frLink,
	children,
}) => {
	const [tabDisplay, setTabDisplay] = useState('designers');

	const langContent = lang == 'en' ? content.en : content.fr;

	const updateTab = (tab) => {
		let input_line = 'input_' + gravityFormId + '_' + tabIdFormField;
		if (document.getElementById(input_line)) {
			(document.getElementById(input_line) as HTMLInputElement).value =
				tab;
		}
		setTabDisplay(tab);
	};

	useLayoutEffect(() => {
		updateTab('designers');
		gsap.registerPlugin(ScrollTrigger);

		// Hero animation
		const tl = gsap.timeline();
		tl.to('.landing-page-hero--intro-text', {
			duration: 1,
			opacity: 1,
			delay: 0.25,
		})
			.to(
				'.door-image-mask-wrapper',
				{
					duration: 1,
					webkitMaskSize: '200px',
				},
				'-=1',
			)
			.to('.landing-page-hero--intro-text', {
				duration: 1,
				opacity: 0,
				delay: 0.5,
			})
			.to(
				'.door-image-mask-wrapper',
				{
					duration: 2,
					webkitMaskSize: '200%',
					delay: 1,
				},
				'-=1',
			)
			.to(
				'.hero-image--overlay',
				{
					duration: 0.5,
					opacity: 1,
				},
				'-=1.25',
			)

			.to(
				'.landing-page-hero--content-wrapper',
				{
					duration: 1,
					opacity: 1,
					onComplete: () => {
						document
							.querySelector('.florz-landing-page')
							.classList.add('intro-revealed');
					},
				},
				'<',
			)
			.fromTo(
				'.florz-landing-header',
				{
					opacity: 0,
				},
				{
					duration: 0.5,
					opacity: 1,
				},
				'-=0.75',
			);

		//End Hero animation

		//Introduction Animation Loops
		//Note: This would ideally be in a component and using `ref` over a forEach()
		const introSections = document.querySelectorAll('.introduction');

		if (introSections && introSections.length > 0) {
			introSections.forEach((intro) => {
				const introImage1 = intro.querySelector(
					'.offset-image-1.will-animate .masked-image',
				);
				const introImage2 = intro.querySelector(
					'.offset-image-2.will-animate .masked-image',
				);
				const introTl = gsap.timeline({
					scrollTrigger: {
						trigger: intro,
						start: '20% center',
					},
				});

				introTl.to(introImage2, {
					duration: 1,
					transform: 'translateY(0px)',
					opacity: 1,
				});
				introTl.to(
					introImage1,
					{
						duration: 1,
						transform: 'translateY(0px)',
						opacity: 1,
					},
					'-=0.25',
				);
			});
		}

		//End Introduction Loops

		//Introduction Tail Animation
		const introTailTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.introduction-tail',
				start: 'top center',
			},
		});

		introTailTl.to('.introduction-tail h2', {
			duration: 1,
			transform: 'translateY(0px)',
			opacity: 1,
		});

		//End Introduction Tail

		//Product Carousel Intro Animation
		const productIntroTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.product-intro-head',
				start: '10% center',
			},
		});

		productIntroTl.to(
			'.product-intro-head h2',
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.25',
		);
		productIntroTl.to(
			'.product-intro-head h3',
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.5',
		);
		productIntroTl.to(
			'.product-intro-head p',
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.5',
		);

		//End Product Carousel Intro Animation

		//Product Carousel Animation & Logic Functionality
		//Note: This would ideally be in a component and using `ref` over a forEach()
		const carousels = document.querySelectorAll(
			'.product-carousel-showcase',
		);

		if (carousels && carousels.length > 0) {
			carousels.forEach((carousel) => {
				const swatches = carousel.querySelectorAll('.swatches li');
				const swatchButtons =
					carousel.querySelectorAll('.swatches li a');
				const doorImages = carousel.querySelectorAll(
					`.carousel-items--door-mask-container .carousel-lightbox-button`,
				);
				const carouselId = carousel.id;
				const lightBoxSiblingTargets = document.querySelectorAll(
					`a[data-lightbox-toggle-target="${carouselId}"]`,
				);

				lightBoxSiblingTargets.forEach((siblingButton) => {
					const chosenSwatch =
						siblingButton.getAttribute('data-swatch-target') ||
						null;

					siblingButton.addEventListener('click', (e) => {
						e.preventDefault();
						//If the lightbox has a designated slide to open to, open to that slide first, then open lightbox.
						//Else just open lightbox.
						if (chosenSwatch) {
							const currentSlide = carousel.querySelector(
								'.carousel-items--door-mask-container .carousel-lightbox-button.active',
							);
							changeSwatch(
								swatchButtons[chosenSwatch],
								currentSlide,
							);
							setTimeout(() => {
								lightBoxToggle();
							}, 250);
						} else {
							lightBoxToggle();
						}
					});
				});

				const lightBoxButtons = carousel.querySelectorAll(
					'.carousel-lightbox-button',
				);

				const lightBoxSlides = carousel.querySelectorAll(
					'.carousel-lightbox--slide',
				);

				const carouselButtons =
					carousel.querySelectorAll('.carousel-control');

				const captions = carousel.querySelectorAll(
					'.carousel-items--captions-container > p',
				);

				// - Product Carousel Animation
				const FlorzShowcaseTl = gsap.timeline({
					scrollTrigger: {
						trigger: carousel,
						start: 'top 5%',
					},
				});

				FlorzShowcaseTl.to(
					swatches,
					{
						duration: 1,
						transform: 'translateX(0px)',
						opacity: 1,
						stagger: {
							each: 0.25,
						},
					},
					'-=0.5',
				);
				// - End Product Carousel Animation

				// - Product Carousel Functionality Logic
				//Note: This is purely custom carousel logic in order to have better UI animation control (Multiple changing elements)
				//Note: Ideally needs aria-labels involved as well
				swatchButtons.forEach((swatch) => {
					swatch.addEventListener('click', (e) => {
						e.preventDefault();
						const currentSlide = carousel.querySelector(
							'.carousel-items--door-mask-container .carousel-lightbox-button.active',
						);

						changeSwatch(swatch, currentSlide);
					});
				});

				lightBoxButtons.forEach((lightboxButton) => {
					lightboxButton.addEventListener('click', (e) => {
						e.preventDefault();
						lightBoxToggle();
					});
				});

				const closeLightboxBtn = carousel.querySelector(
					'.carousel-lightbox--close-button a',
				);

				if (closeLightboxBtn) {
					closeLightboxBtn.addEventListener('click', (e) => {
						e.preventDefault();
						lightBoxToggle();
					});
				}

				const lightboxWrapper = carousel.querySelector(
					'.carousel-lightbox-wrapper',
				);
				const lightBoxContentWrapper = carousel.querySelector(
					'.carousel-lightbox--content-wrapper',
				);

				lightboxWrapper.addEventListener('click', (e) => {
					if (
						e.target == lightboxWrapper ||
						e.target == lightBoxContentWrapper
					) {
						lightBoxToggle();
					}
				});

				const lightBoxToggle = () => {
					if (lightboxWrapper.classList.contains('animating')) {
						return;
					}

					lightboxWrapper.classList.add('animating');

					if (lightboxWrapper.classList.contains('active')) {
						gsap.fromTo(
							lightboxWrapper,
							{
								display: 'flex',
								opacity: 1,
							},
							{
								duration: 0.75,
								display: 'none',
								opacity: 0,
								onComplete: () => {
									lightboxWrapper.classList.toggle('active');
									lightboxWrapper.classList.remove(
										'animating',
									);
								},
							},
						);
					} else {
						lightboxWrapper.classList.toggle('active'); //For instant z-index manipulation
						gsap.fromTo(
							lightboxWrapper,
							{
								opacity: 0,
								display: 'none',
							},
							{
								delay: 0.2,
								duration: 0.5,
								display: 'flex',
								opacity: 1,
								onComplete: () => {
									lightboxWrapper.classList.remove(
										'animating',
									);
								},
							},
						);
					}

					document
						.querySelector('body')
						.classList.toggle('disable-scroll');
				};

				carouselButtons.forEach((btn) => {
					btn.addEventListener('click', (e) => {
						e.preventDefault();
						const currentSlide = carousel.querySelector(
							'.carousel-items--door-mask-container .carousel-lightbox-button.active',
						);
						const slideLength = doorImages.length;
						const slideLengthArrayLength =
							slideLength == 0 ? 0 : slideLength - 1;

						let currentIndex =
							Array.from(doorImages).indexOf(currentSlide);

						if (btn.classList.contains('prev')) {
							if (currentIndex == 0) {
								currentIndex = slideLengthArrayLength;
							} else {
								currentIndex--;
							}
						}

						if (btn.classList.contains('next')) {
							if (currentIndex == slideLengthArrayLength) {
								currentIndex = 0;
							} else {
								currentIndex++;
							}
						}

						changeSwatch(swatchButtons[currentIndex], currentSlide);
					});
				});

				const changeSwatch = (swatch, currentSlide) => {
					const swatchTarget = swatch.getAttribute('data-target');
					const currentCaption = carousel.querySelector(
						'.carousel-items--captions-container > p.active',
					);
					const currentSwatch = carousel.querySelector(
						'.swatches li a.active',
					);
					const currentLightBoxSlide = carousel.querySelector(
						'.carousel-lightbox--slide.active',
					);

					if (
						doorImages[swatchTarget] === undefined ||
						(doorImages[swatchTarget] &&
							doorImages[swatchTarget].classList.contains(
								'active',
							)) ||
						currentCaption == null
					) {
						return;
					}

					currentSlide.classList.add('previous');
					currentSlide.classList.remove('active');
					currentSwatch.classList.remove('active');
					currentCaption.classList.remove('active');
					swatch.classList.add('active');
					doorImages[swatchTarget].classList.add('active');

					gsap.timeline()
						.fromTo(
							currentLightBoxSlide,
							{ transform: 'scale(1)', opacity: 1 },
							{
								transform: 'scale(1.01)',
								duration: 0.5,
								opacity: 0,
								onComplete: () => {
									currentLightBoxSlide.classList.remove(
										'active',
									);
									lightBoxSlides[swatchTarget].classList.add(
										'active',
									);
								},
							},
						)
						.fromTo(
							lightBoxSlides[swatchTarget],
							{ transform: 'scale(1.01)', opacity: 0 },
							{
								transform: 'scale(1)',
								duration: 0.5,
								opacity: 1,
							},
						);

					gsap.fromTo(
						doorImages[swatchTarget],
						{ transform: 'scale(1.1)', opacity: 0 },
						{
							transform: 'scale(1)',
							duration: 0.75,
							opacity: 1,
							onComplete: () => {
								currentSlide.classList.remove('previous');
								captions[swatchTarget].classList.add('active');
							},
						},
					);
				};
				// End Product Carousel Functionality Logic
			});
		}

		//End Product Carousel Animation & Logic Functionality

		//Accordion Animation & Logic Functionality
		//Note: This would ideally be in a component and using `ref` over a forEach()
		//Note: Functionality is purely custom made to include GSAP integration
		const productSpecifications = document.querySelectorAll(
			'.specifications-row',
		);
		if (productSpecifications && productSpecifications.length > 0) {
			productSpecifications.forEach((accordion) => {
				const accordionButton = accordion.querySelector(
					'.specification-button-toggle',
				);
				const accordionTarget = accordion.querySelector(
					'.specifications-row--results',
				);
				accordionButton.addEventListener('click', (e) => {
					e.preventDefault();
					if (accordionTarget.classList.contains('animating')) {
						return;
					}

					if (accordion.classList.contains('active')) {
						accordion.classList.remove('active');
						return;
					}

					accordion.classList.add('active');
					accordionTarget.classList.add('animating');

					const listEls = accordionTarget.querySelectorAll(
						'.specifications-row--listing > li',
					);
					gsap.fromTo(
						listEls,
						{ y: -20, opacity: 0 },
						{
							y: 0,
							duration: 0.25,
							opacity: 1,
							stagger: {
								each: 0.05,
							},
							onComplete: () => {
								accordionTarget.classList.remove('animating');
							},
						},
					);
				});
			});
		}
		//End Accordion Animation & Logic Functionality

		//Made in Europe Animation
		const madeInEuropeTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.made-in-europe',
				start: '0% center',
			},
		});

		madeInEuropeTl.to('.made-in-europe', {
			duration: 1,
			background:
				'linear-gradient(90deg, #1c1c23 0%, rgb(28 28 35 / 0%) 100%)',
		});

		madeInEuropeTl.to(
			'.made-in-europe--copy-wrapper h2',
			{
				duration: 1,
				transform: 'translateX(0px)',
				opacity: 1,
			},
			'-=1',
		);

		madeInEuropeTl.to(
			'.made-in-europe--copy-wrapper p',
			{
				duration: 1,
				transform: 'translateX(0px)',
				opacity: 1,
			},
			'-=0.5',
		);

		//End Made in Europe Animation

		//Alternating Text Rows Animation
		//Note: This would ideally be in a component and using `ref` over a forEach()
		const alternatingTextItems =
			document.querySelectorAll('.aac-content-row');

		if (alternatingTextItems && alternatingTextItems.length > 0) {
			alternatingTextItems.forEach((item) => {
				const itemImg = item.querySelector(
					'.aac-content-row--image-wrapper',
				);
				const itemHeader = item.querySelector(
					'.aac-content-row--info-wrapper h3',
				);
				const itemText = item.querySelector(
					'.aac-content-row--info-wrapper p',
				);

				gsap.fromTo(
					itemHeader,
					{ transform: 'translateY(50px)', opacity: 0 },
					{
						duration: 1,
						transform: 'translateY(0px)',
						opacity: 1,
						scrollTrigger: {
							trigger: item,
						},
					},
				);

				gsap.fromTo(
					itemText,
					{ transform: 'translateY(50px)', opacity: 0 },
					{
						duration: 1,
						delay: 0.25,
						transform: 'translateY(0px)',
						opacity: 1,
						scrollTrigger: {
							trigger: item,
						},
					},
				);

				//This turns on the parallax scrolling on tablet+
				gsap.matchMedia().add('(min-width: 768px)', () => {
					gsap.to(itemImg, {
						y: -100,
						scrollTrigger: {
							trigger: item,
							scrub: true,
							ease: 'power2.out',
						},
					});
				});
			});
		}
		//End Alternating Text Rows Animation

		//Form Tabs Animation & Functionality Logic
		//Note: This would ideally be in a component and using `ref` over a forEach()
		//Note: Functionality is purely custom made to include GSAP integration
		const formTab = document.querySelector('.form-tabs-container');
		const formTabButtons = formTab.querySelectorAll(
			'.form-tabs-container--buttons-wrapper ul li a',
		);

		formTabButtons.forEach((tabButton) => {
			const formTabs = document.querySelectorAll(
				'.form-tabs-container--tabs-content-wrapper .inquiry-form-block',
			);
			tabButton.addEventListener('click', (e) => {
				e.preventDefault();
				const currentTabButton = formTab.querySelector(
					'.form-tabs-container--buttons-wrapper ul li a.active',
				);
				const currentTab = formTab.querySelector(
					'.form-tabs-container--tabs-content-wrapper .inquiry-form-block.active',
				);
				const tabTarget = tabButton.getAttribute('data-target');

				if (
					!formTabs ||
					formTabs[tabTarget] == null ||
					currentTab.classList.contains('animating')
				) {
					return;
				}

				currentTabButton.classList.remove('active');
				tabButton.classList.add('active');
				currentTab.classList.add('animating');

				const tabTl = gsap.timeline();

				tabTl
					.fromTo(
						currentTab,
						{ display: 'flex', opacity: 1 },
						{
							duration: 0.15,
							opacity: 0,
							display: 'none',
							onComplete: () => {
								currentTab.classList.remove('active');
								currentTab.classList.remove('animating');
							},
						},
					)
					.fromTo(
						formTabs[tabTarget],
						{
							opacity: 0,
							display: 'none',
						},
						{
							duration: 0.15,
							delay: 0.05,
							opacity: 1,
							display: 'flex',
							onComplete: () => {
								formTabs[tabTarget].classList.add('active');
							},
						},
					);
			});
		});
		//End Form Tabs Animation & Functionality Logic
		return () => {
			// cleanup code (optional)
		};
	}, []);

	return (
		<main
			className={`florz-landing-page florz-landing-page--static ${lang}`}
		>
			{/* Header Section */}
			<header className="florz-landing-header">
				<div className="florz-landing-header--content-wrapper">
					<a
						href={langContent.global.homeBackButton.href}
						className="back-button-wrapper"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27 16H5"
								stroke="#E3D9C6"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M14 7L5 16L14 25"
								stroke="#E3D9C6"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>{langContent.global.homeBackButton.label}</span>
					</a>
					<div className="logo-wrapper">
						<Image {...logoImage}></Image>
					</div>
					<div className="language-switcher-container">
						<ul>
							<li>
								<a
									href={enLink}
									className={lang === 'en' ? 'active' : ''}
								>
									En
								</a>
							</li>
							<li>
								<a
									href={frLink}
									className={lang === 'fr' ? 'active' : ''}
								>
									Fr
								</a>
							</li>
						</ul>
					</div>
				</div>
			</header>
			{/* End Header Section */}

			{/* Hero Section */}
			<div className="landing-page-hero">
				<div className="landing-page-hero--intro-text">
					<span>{langContent.hero.eyebrow}</span>
				</div>
				<div className="landing-page-hero--content-wrapper">
					<h6 className="leading-title">
						{langContent.hero.eyebrow}
					</h6>
					<div className="title-wrapper">
						<h1 className="hero-title">
							<span className="hero-title--eyebrow subheading">
								{langContent.hero.title.top}
							</span>
							<span className="hero-title--title h1-large">
								{langContent.hero.title.middle}
							</span>
							<span className="hero-title--subtitle h4">
								{langContent.hero.title.bottom}
							</span>
						</h1>
					</div>
					<div className="excerpt-wrapper">
						<p className="subheading">{langContent.hero.excerpt}</p>
						<Button href={'#' + langContent.hero.button.href}>
							{langContent.hero.button.label}
						</Button>
					</div>
				</div>
				<div className="landing-page-hero--image-wrapper">
					<div className="hero-image--overlay"></div>
					<div
						className="door-image-mask-wrapper"
						style={{
							maskImage: `url(${doorMask})`,
							WebkitMaskImage: `url(${doorMask})`,
							maskRepeat: 'no-repeat',
							WebkitMaskRepeat: 'no-repeat',
							maskPosition: 'center',
							WebkitMaskPosition: 'center',
							maskSize: '0%',
							WebkitMaskSize: '0%',
						}}
					>
						<video loop autoPlay muted>
							<source
								src={videoTreesWebm}
								type="video/webm"
							></source>
							<source src={videoTrees} type="video/mp4"></source>
						</video>
						<AdvancedImage image={heroSplashImage}></AdvancedImage>
					</div>
				</div>
			</div>
			{/* End Hero Section */}

			{/* Introduction Section */}
			<div className="introduction standard-container">
				<div
					className="herringbone-accent"
					style={{
						backgroundImage: `url(${herringBonePattern})`,
					}}
				></div>
				<div className="standard-row">
					<div className="introduction--content-wrapper">
						<h2>{langContent.introduction.title}</h2>
						<p>{langContent.introduction.excerpt}</p>

						<ul className="highlight-list">
							<li>
								<div className="highlight-row">
									<Image {...iconOakImage}></Image>
									<span className="highlight-copy">
										{langContent.introduction.highlights[0]}
									</span>
								</div>
							</li>
							<li>
								<div className="highlight-row">
									<Image {...iconMadeInEuropeImage}></Image>
									<span className="highlight-copy">
										{langContent.introduction.highlights[1]}
									</span>
								</div>
							</li>
							<li>
								<div className="highlight-row">
									<Image {...icon35YearWarrantyImage}></Image>
									<span className="highlight-copy">
										{langContent.introduction.highlights[2]}
									</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="introduction--image-abstract-grid">
						<MaskedDoorImage
							className="offset-image-1"
							image={chevronShowcaseImage}
							caption={
								langContent.introduction.firstImageCaption.label
							}
							pretext={langContent.global.captionPretext}
							lightboxTarget={
								langContent.introduction.firstImageCaption.href
							}
							lightboxSwatchIndex={
								langContent.introduction.firstImageCaption
									.swatchKey
							}
							captionUrl={`#${langContent.introduction.firstImageCaption.href}`}
							willAnimate={true}
						/>
						<MaskedDoorImage
							className="offset-image-2"
							image={herringboneShowcaseImage}
							mask={'edge'}
							caption={
								langContent.introduction.secondImageCaption
									.label
							}
							pretext={langContent.global.captionPretext}
							lightboxTarget={
								langContent.introduction.secondImageCaption.href
							}
							lightboxSwatchIndex={
								langContent.introduction.secondImageCaption
									.swatchKey
							}
							captionUrl={`#${langContent.introduction.secondImageCaption.href}`}
							willAnimate={true}
						/>
					</div>
				</div>
			</div>
			{/* ===End Introduction Section */}

			{/* Introduction Tail Section */}
			<div className="introduction-tail">
				<h2>{langContent.introductionTailTitle}</h2>
				<div className="introduction-overlay">
					<video className="overlay-video" loop autoPlay muted>
						<source
							src={videoCloudsWebm}
							type="video/webm"
						></source>
						<source src={videoClouds} type="video/mp4"></source>
					</video>
					<AdvancedImage
						image={renaissanceBackdropImage}
					></AdvancedImage>
				</div>
			</div>
			{/* ===End Introduction Tail Section */}

			{/* Product Carousel Introduction*/}
			<div className="product-intro-head">
				<div className="product-intro-head--header-wrapper">
					<h2 className="h3 fade-up">
						{langContent.productsIntro.title.pretext}{' '}
						<i>{langContent.productsIntro.title.italicizedText}</i>
					</h2>
					<h3 className="h4 fade-up">
						{langContent.productsIntro.subtitle}
					</h3>
					<p className="fade-up">
						{langContent.productsIntro.excerpt}
					</p>
				</div>
			</div>
			{/* ===End Product Carousel Introduction*/}

			{/* Product Carousel (Multiple of these sections) */}
			<div
				id={langContent.introduction.secondImageCaption.href}
				className="product-carousel-showcase"
			>
				<div className="carousel-lightbox-wrapper">
					<div className="carousel-lightbox--close-button">
						<a href="#">
							<span></span>
							<p className="visually-hidden">Close</p>
							<span></span>
						</a>
					</div>
					<div className="carousel-lightbox--content-wrapper">
						<a
							href="#"
							className="carousel-lightbox--control carousel-control prev"
						>
							<span className="visually-hidden">Prev</span>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M27 16H5"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14 7L5 16L14 25"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
						<div className="carousel-lightbox--slides">
							<div
								className="carousel-lightbox--slide active"
								data-target="0"
							>
								<AdvancedImage
									image={herringboneCanyonImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[0].caption
									}
								</p>
							</div>

							<div
								className="carousel-lightbox--slide"
								data-target="1"
							>
								<AdvancedImage
									image={herringboneCitadelImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[1].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="2"
							>
								<AdvancedImage
									image={herringboneCavallinaImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[2].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="3"
							>
								<AdvancedImage
									image={herringboneVallettaImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[3].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="4"
							>
								<AdvancedImage
									image={herringboneArabelleImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[4].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="5"
							>
								<AdvancedImage
									image={herringboneEstateImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[5].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="6"
							>
								<AdvancedImage
									image={herringboneSnowPeakImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[6].caption
									}
								</p>
							</div>
						</div>
						<a
							href="#"
							className="carousel-lightbox--control carousel-control next"
						>
							<span className="visually-hidden">Next</span>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5 16H27"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M18 7L27 16L18 25"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
					</div>
				</div>
				<div className="product-carousel-showcase--carousel-wrapper">
					<div className="product-carousel-showcase--canvas-wrapper">
						<div className="carousel-arrows">
							<a href="#" className="carousel-control prev">
								<span className="visually-hidden">Prev</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
							<a href="#" className="carousel-control next">
								<span className="visually-hidden">Next</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5 16H27"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18 7L27 16L18 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
						</div>
						<div className="carousel-items">
							<div
								className="carousel-items--door-mask-container"
								style={{
									maskImage: `url(${doorMaskWithEdgeFlipped})`,
									WebkitMaskImage: `url(${doorMaskWithEdgeFlipped})`,
									maskRepeat: 'no-repeat',
									WebkitMaskRepeat: 'no-repeat',
									maskPosition: 'center',
									WebkitMaskPosition: 'center',
									maskSize: 'contain',
									WebkitMaskSize: 'contain',
								}}
							>
								<a
									href="#"
									className="carousel-lightbox-button active"
									data-lightbox-target="0"
								>
									<AdvancedImage
										image={herringboneCanyonImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="1"
								>
									<AdvancedImage
										image={herringboneCitadelImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="2"
								>
									<AdvancedImage
										image={herringboneCavallinaImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="3"
								>
									<AdvancedImage
										image={herringboneVallettaImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="4"
								>
									<AdvancedImage
										image={herringboneArabelleImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="5"
								>
									<AdvancedImage
										image={herringboneEstateImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="6"
								>
									<AdvancedImage
										image={herringboneSnowPeakImage}
									></AdvancedImage>
								</a>
							</div>
							<div className="carousel-items--captions-container">
								<p className="active">
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[0].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[1].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[2].caption
									}
								</p>

								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[3].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[4].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[5].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselHerringbone
											.swatches[6].caption
									}
								</p>
							</div>
						</div>
					</div>
					<div className="product-carousel-showcase--info-wrapper">
						<div className="info-wrapper--head">
							<h2>
								{langContent.productCarouselHerringbone.title}
							</h2>
							<p>
								{langContent.productCarouselHerringbone.excerpt}
							</p>
							<a
								href={
									langContent.productCarouselHerringbone
										.pdfUrl
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								{
									langContent.productCarouselHerringbone
										.specSheetLabel
								}
							</a>
							<div className="available-patterns">
								<p>{langContent.global.availablePatterns}:</p>
								<ul>
									<li>
										<Image
											{...herringboneIconImage}
										></Image>
										<span>
											{
												langContent
													.productCarouselHerringbone
													.patternLabel1
											}
										</span>
									</li>
									<li>
										<Image
											{...straightPlankIconImage}
										></Image>
										<span>
											{
												langContent
													.productCarouselHerringbone
													.patternLabel2
											}
										</span>
									</li>
								</ul>
							</div>
							<div className="swatches-wrapper">
								<ul className="swatches">
									<li>
										<a
											href="#"
											data-target="0"
											className="active"
										>
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={canyonSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[0].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="1">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={citadelSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[1].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="2">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={cavallinaSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[2].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="3">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={vallettaSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[3].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="4">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={arabelleSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[4].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="5">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={estateSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[5].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="6">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={snowPeakSwatchImage}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselHerringbone
														.swatches[6].name
												}
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="product-carousel-showcase--specifications">
					<div className="specifications-row">
						<a href="#" className="specification-button-toggle">
							<div className="specifications-icon-indicator">
								<div></div>
								<div></div>
							</div>
							<span className="h5">
								{
									langContent.productCarouselHerringbone
										.accordionLabel
								}
							</span>
						</a>

						<div className="specifications-row--results">
							<ul className="specifications-row--listing">
								{langContent.productCarouselHerringbone.measurements.map(
									(measurement, key) => {
										return (
											<li key={key}>
												<span className="measurement">
													{measurement.measurement}
												</span>
												<span className="specification">
													{measurement.spec}
												</span>
											</li>
										);
									},
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* End Product Carousel */}

			{/* Product Carousel (Multiple of these sections) */}
			<div
				id={langContent.introduction.firstImageCaption.href}
				className="product-carousel-showcase with-gradient"
			>
				<div
					className="herringbone-accent"
					style={{
						backgroundImage: `url(${chevronPattern})`,
					}}
				></div>
				<div className="carousel-lightbox-wrapper">
					<div className="carousel-lightbox--close-button">
						<a href="#">
							<span></span>
							<p className="visually-hidden">Close</p>
							<span></span>
						</a>
					</div>
					<div className="carousel-lightbox--content-wrapper">
						<a
							href="#"
							className="carousel-lightbox--control carousel-control prev"
						>
							<span className="visually-hidden">Prev</span>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M27 16H5"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14 7L5 16L14 25"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
						<div className="carousel-lightbox--slides">
							<div
								className="carousel-lightbox--slide active"
								data-target="0"
							>
								<AdvancedImage
									image={chevronCanyonImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[0].caption
									}
								</p>
							</div>

							<div
								className="carousel-lightbox--slide"
								data-target="1"
							>
								<AdvancedImage
									image={chevronCitadelImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[1].caption
									}
								</p>
							</div>
							<div
								className="carousel-lightbox--slide"
								data-target="2"
							>
								<AdvancedImage
									image={chevronEstateImage}
								></AdvancedImage>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[2].caption
									}
								</p>
							</div>
						</div>
						<a
							href="#"
							className="carousel-lightbox--control carousel-control next"
						>
							<span className="visually-hidden">Next</span>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5 16H27"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M18 7L27 16L18 25"
									stroke="#E3D9C6"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
					</div>
				</div>
				<div className="product-carousel-showcase--carousel-wrapper flipped">
					<div className="product-carousel-showcase--canvas-wrapper">
						<div className="carousel-arrows">
							<a href="#" className="carousel-control prev">
								<span className="visually-hidden">Prev</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
							<a href="#" className="carousel-control next">
								<span className="visually-hidden">Next</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5 16H27"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18 7L27 16L18 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
						</div>
						<div className="carousel-items">
							<div
								className="carousel-items--door-mask-container"
								style={{
									maskImage: `url(${doorMaskWithEdge})`,
									WebkitMaskImage: `url(${doorMaskWithEdge})`,
									maskRepeat: 'no-repeat',
									WebkitMaskRepeat: 'no-repeat',
									maskPosition: 'center',
									WebkitMaskPosition: 'center',
									maskSize: 'contain',
									WebkitMaskSize: 'contain',
								}}
							>
								<a
									href="#"
									className="carousel-lightbox-button active"
									data-lightbox-target="0"
								>
									<AdvancedImage
										image={chevronCanyonImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="1"
								>
									<AdvancedImage
										image={chevronCitadelImage}
									></AdvancedImage>
								</a>
								<a
									href="#"
									className="carousel-lightbox-button"
									data-lightbox-target="2"
								>
									<AdvancedImage
										image={chevronEstateImage}
									></AdvancedImage>
								</a>
							</div>
							<div className="carousel-items--captions-container">
								<p className="active">
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[0].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[1].caption
									}
								</p>
								<p>
									{langContent.global.captionPretext}{' '}
									{
										langContent.productCarouselChevron
											.swatches[2].caption
									}
								</p>
							</div>
						</div>
					</div>
					<div className="product-carousel-showcase--info-wrapper">
						<div className="info-wrapper--head">
							<h2>{langContent.productCarouselChevron.title}</h2>
							<p>{langContent.productCarouselChevron.excerpt}</p>
							<a
								href={langContent.productCarouselChevron.pdfUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								{
									langContent.productCarouselChevron
										.specSheetLabel
								}
							</a>
							<div className="available-patterns">
								<p>{langContent.global.availablePatterns}:</p>
								<ul>
									<li>
										<Image {...chevronIconImage}></Image>
										<span>
											{
												langContent
													.productCarouselChevron
													.patternLabel1
											}
										</span>
									</li>
									<li>
										<Image
											{...straightPlankIconImage}
										></Image>
										<span>
											{
												langContent
													.productCarouselChevron
													.patternLabel2
											}
										</span>
									</li>
								</ul>
							</div>
							<div className="swatches-wrapper">
								<ul className="swatches">
									<li>
										<a
											href="#"
											data-target="0"
											className="active"
										>
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={
														chevronCanyonSwatchImage
													}
												></AdvancedImage>
											</div>
											<span>
												{' '}
												{
													langContent
														.productCarouselChevron
														.swatches[0].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="1">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={
														chevronCitadelSwatchImage
													}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselChevron
														.swatches[1].name
												}
											</span>
										</a>
									</li>
									<li>
										<a href="#" data-target="2">
											<div className="swatch-icon-wrapper">
												<AdvancedImage
													image={
														chevronEstateSwatchImage
													}
												></AdvancedImage>
											</div>
											<span>
												{
													langContent
														.productCarouselChevron
														.swatches[2].name
												}
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="product-carousel-showcase--specifications">
					<div className="specifications-row">
						<a href="#" className="specification-button-toggle">
							<div className="specifications-icon-indicator">
								<div></div>
								<div></div>
							</div>
							<span className="h5">
								{
									langContent.productCarouselChevron
										.accordionLabel
								}
							</span>
						</a>

						<div className="specifications-row--results">
							<ul className="specifications-row--listing">
								{langContent.productCarouselChevron.measurements.map(
									(measurement, key) => {
										return (
											<li key={key}>
												<span className="measurement">
													{measurement.measurement}
												</span>
												<span className="specification">
													{measurement.spec}
												</span>
											</li>
										);
									},
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* End Product Carousel */}

			{/* Made In Europe Section */}
			<div className="made-in-europe">
				<div className="made-in-europe--content-wrapper">
					<div className="made-in-europe--image-wrapper">
						<Image {...madeInEuropeImage}></Image>
					</div>
					<div className="made-in-europe--copy-wrapper">
						<h2 className="fade-up">
							{langContent.madeInEurope.title}
						</h2>
						<p className="fade-up">
							{langContent.madeInEurope.excerpt}
						</p>
					</div>
				</div>
			</div>
			{/* End Made In Europe Section */}

			{/* Alternating Content */}
			<div className="abstract-alternating-content">
				<div className="abstract-alternating-content--content-wrapper">
					<h2>{langContent.alternatingContent.title}</h2>
					<div className="aac-content-listings">
						{/* Alternating Content - Row Looping */}
						<div className="aac-content-row left">
							<div className="aac-content-row--content">
								<div className="aac-content-row--image-wrapper">
									<AdvancedImage
										image={altBubble1Image}
									></AdvancedImage>
								</div>
								<div className="aac-content-row--info-wrapper">
									<h3>
										{
											langContent.alternatingContent
												.rows[0].title
										}
									</h3>
									<p>
										{
											langContent.alternatingContent
												.rows[0].excerpt
										}
									</p>
								</div>
							</div>
						</div>
						<div className="aac-content-row center">
							<div className="aac-content-row--content">
								<div className="aac-content-row--image-wrapper">
									<AdvancedImage
										image={altBubble2Image}
									></AdvancedImage>
								</div>
								<div className="aac-content-row--info-wrapper">
									<h3>
										{
											langContent.alternatingContent
												.rows[1].title
										}
									</h3>
									<p>
										{
											langContent.alternatingContent
												.rows[1].excerpt
										}
									</p>
								</div>
							</div>
						</div>
						<div className="aac-content-row right">
							<div className="aac-content-row--content">
								<div className="aac-content-row--image-wrapper">
									<AdvancedImage
										image={altBubble3Image}
									></AdvancedImage>
								</div>
								<div className="aac-content-row--info-wrapper">
									<div className="aac-content-row--info-wrapper">
										<h3>
											{
												langContent.alternatingContent
													.rows[2].title
											}
										</h3>
										<p>
											{
												langContent.alternatingContent
													.rows[2].excerpt
											}
										</p>
									</div>
								</div>
							</div>
						</div>
						{/* Alternating Content - End Row Looping */}
					</div>
				</div>
			</div>
			{/* End Alternating Content */}

			{/* Introduction Section  w/ wallpaper*/}
			<div className="introduction standard-container has-wallpaper">
				<div
					className="introduction-wallpaper"
					style={{
						backgroundImage: `url(${introWallpaper})`,
					}}
				></div>
				<div className="standard-row">
					<div className="introduction--content-wrapper">
						<h2>{langContent.introduction2.title}</h2>
						<p>{langContent.introduction2.excerpt}</p>

						<ul className="highlight-list numbers">
							<li>
								<div className="highlight-row">
									<span className="highlight-number stat-num">
										{
											langContent.introduction2
												.highlights[0].stat
										}
									</span>
									<span className="highlight-copy">
										{
											langContent.introduction2
												.highlights[0].label
										}
									</span>
								</div>
							</li>
							<li>
								<div className="highlight-row">
									<span className="highlight-number stat-num">
										{
											langContent.introduction2
												.highlights[1].stat
										}
									</span>
									<span className="highlight-copy">
										{
											langContent.introduction2
												.highlights[1].label
										}
									</span>
								</div>
							</li>
							<li>
								<div className="highlight-row">
									<span className="highlight-number stat-num">
										{
											langContent.introduction2
												.highlights[2].stat
										}
									</span>
									<span className="highlight-copy">
										{
											langContent.introduction2
												.highlights[2].label
										}
									</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="introduction--image-abstract-grid">
						<MaskedDoorImage
							className="offset-image-1"
							image={florzFamilyBanner1Image}
							willAnimate={true}
						/>
						<MaskedDoorImage
							className="offset-image-2"
							image={florzFamilyBanner2Image}
							mask={'edge'}
							willAnimate={true}
						/>
					</div>
				</div>
			</div>
			{/* End Introduction Section */}

			{/* Form Tabs Section */}
			<div
				id={langContent.hero.button.href}
				className="inquiry-forms-container"
			>
				<div className="inquiry-forms-container--content-wrapper">
					<h2>{langContent.inquiries.title}</h2>
					<div className="form-tabs-container">
						<div className="form-tabs-container--buttons-wrapper">
							<ul>
								<li>
									<a
										href="#"
										data-target="0"
										className={
											tabDisplay === 'designers'
												? ' active'
												: ''
										}
										onClick={() => {
											updateTab('designers');
										}}
									>
										{langContent.inquiries.tabs[0].label}
									</a>
								</li>
								<li>
									<a
										href="#"
										data-target="1"
										className={
											tabDisplay === 'retailers'
												? ' active'
												: ''
										}
										onClick={() => {
											updateTab('retailers');
										}}
									>
										{langContent.inquiries.tabs[1].label}
									</a>
								</li>
								<li>
									<a
										href="#"
										data-target="2"
										className={
											tabDisplay === 'consumers'
												? ' active'
												: ''
										}
										onClick={() => {
											updateTab('consumers');
										}}
									>
										{langContent.inquiries.tabs[2].label}
									</a>
								</li>
							</ul>
						</div>
						<div className="form-tabs-container--tabs-content-wrapper">
							<div className="inquiry-tab-aside">
								<div
									className={
										'inquiry-form-block' +
										(tabDisplay === 'designers'
											? ' active'
											: '')
									}
									data-target="0"
								>
									<div className="inquiry-form-block--aside-wrapper">
										<h3>
											{
												langContent.inquiries.tabs[0]
													.title
											}
										</h3>
										<p>
											{
												langContent.inquiries.tabs[0]
													.excerpt
											}
										</p>
									</div>
								</div>
								<div
									className={
										'inquiry-form-block' +
										(tabDisplay === 'retailers'
											? ' active'
											: '')
									}
									data-target="1"
								>
									<div className="inquiry-form-block--aside-wrapper">
										<h3>
											{
												langContent.inquiries.tabs[1]
													.title
											}
										</h3>
										<p>
											{
												langContent.inquiries.tabs[1]
													.excerpt
											}
										</p>
									</div>
								</div>
								<div
									className={
										'inquiry-form-block' +
										(tabDisplay === 'consumers'
											? ' active'
											: '')
									}
									data-target="2"
								>
									<div className="inquiry-form-block--aside-wrapper">
										<h3>
											{
												langContent.inquiries.tabs[2]
													.title
											}
										</h3>
										<p>
											{
												langContent.inquiries.tabs[2]
													.excerpt
											}
										</p>
									</div>
								</div>
							</div>
							<div className="inquiry-form-gf">{children}</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Form Tabs Section */}

			{/* Footer Section */}
			<footer className="florz-landing-footer">
				<div className="florz-landing-footer--content-wrapper">
					<div className="florz-landing-footer--top-row">
						<div className="footer-aside">
							<Image
								className="footer-logo"
								{...logoImage}
							></Image>
							<ul className="social-icons">
								<li>
									<a
										href="https://www.facebook.com/florzflooringcanada/"
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											Facebook
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7 10V14H10V21H14V14H17L18 10H14V8C14 7.73478 14.1054 7.48043 14.2929 7.29289C14.4804 7.10536 14.7348 7 15 7H18V3H15C13.6739 3 12.4021 3.52678 11.4645 4.46447C10.5268 5.40215 10 6.67392 10 8V10H7Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/thefloorbox"
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											Instagram
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M16 4H8C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										href="https://ca.linkedin.com/company/floorbox"
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											LinkedIn
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8 11V16M8 8V8.01M12 16V11M16 16V13C16 12.4696 15.7893 11.9609 15.4142 11.5858C15.0391 11.2107 14.5304 11 14 11C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										href="https://www.youtube.com/channel/UCzeAn_brKsROQveMczt_pbQ"
										target="_blank"
										rel="noreferrer noopener"
									>
										<span className="visually-hidden">
											YouTube
										</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17 5H7C4.79086 5 3 6.79086 3 9V15C3 17.2091 4.79086 19 7 19H17C19.2091 19 21 17.2091 21 15V9C21 6.79086 19.2091 5 17 5Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M10 9L15 12L10 15V9Z"
												stroke="#8C827A"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</a>
								</li>
							</ul>
						</div>
						<ul className="location-listing">
							<li className="location-block">
								<h6>{langContent.footer.canadaOffice.title}</h6>
								<p>{langContent.footer.canadaOffice.address}</p>
							</li>
							<li className="location-block">
								<h6>
									{langContent.footer.canadaWestOffice.title}
								</h6>
								<p>
									{
										langContent.footer.canadaWestOffice
											.address
									}
								</p>
							</li>
							<li className="location-block">
								<h6>{langContent.footer.usOffice.title}</h6>
								<p>{langContent.footer.usOffice.address}</p>
							</li>
						</ul>
					</div>
					<div className="florz-landing-footer--bottom-row">
						<div className="footer-back-button">
							<a
								href={langContent.global.homeBackButton.href}
								className="back-button-wrapper"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<span>
									{langContent.global.homeBackButton.label}
								</span>
							</a>
						</div>
						<div className="footer-bottom-copy-wrapper">
							<div className="footer-credits-wrapper">
								<div className="copyrights">
									<p>{langContent.footer.copyright}</p>
								</div>
								<div className="credit">
									<a
										target="_blank"
										href="https://tbkcreative.com"
										rel="noreferrer noopener"
									>
										<span>
											{langContent.footer.tbkPretext}
										</span>
									</a>
								</div>
							</div>
							<p className="disclaimer">
								{langContent.footer.disclaimer}
							</p>
							<p className="disclaimer">
								{langContent.footer.disclaimer1}
							</p>
						</div>
					</div>
				</div>
			</footer>
			{/* End Footer Section */}
		</main>
	);
};

export default LandingPage;
