import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap, ScrollTrigger } from 'gsap/all';
import Image, { IImage } from 'tbk-components/src/components/Image';
import MaskedDoorImage, { IMaskedDoorImageProps } from '../MaskedDoorImage';
import { IProduct } from '../CollectionCarousels';
import AdvancedImage from '../AdvancedImage';

export interface IFlorzInfoProps extends IBaseElementProps {
	accentPattern?: IImage;
	title?: string;
	description?: string;
	highlights?: IHighlight[];
	wallpaper?: IImage;
	//There is a finite amount they can add and that it why it is not using an array[]
	featuredImage1?: IFeaturedImage;
	featuredImage2?: IFeaturedImage;
	reducedPadding?: boolean;
	/*
	 * These are used for Living Wood and will indent this section. Only use this is the section above is the hero.
	 */
	accentImage?: IImage;
}

export interface IFeaturedImage {
	image?: IMaskedDoorImageProps;
	/*
	 * An optional lightbox gallery. This will be used to populate a gallery when the image is clicked.
	 * Setting a lightbox will overwrite the image link.
	 */
	lightbox?: IProduct[];
}

export interface IHighlight {
	icon?: IImage;
	/*
	 * A highlighted text will overwrite the Icon image. Only one can be present.
	 */
	highlightText?: string;
	caption?: string;
}

/**
 * FlorzInfo
 *
 * This component is used to display a collection of information.
 * It can have lightbox galleries attached to the featured images.
 *
 * @icon book-alt
 * @block
 */

const FlorzInfo: React.FC<IFlorzInfoProps> = ({
	accentPattern,
	title,
	description,
	wallpaper,
	highlights = [],
	featuredImage1,
	featuredImage2,
	reducedPadding = false,
	accentImage,
}) => {
	const FlorzInfo = useRef(null);

	useEffect(() => {
		if (!FlorzInfo.current) {
			return;
		}
		gsap.registerPlugin(ScrollTrigger);

		const info = FlorzInfo.current;
		const infoImage1 = info.querySelector(
			'.offset-image-1.will-animate .masked-image',
		);
		const infoImage2 = info.querySelector(
			'.offset-image-2.will-animate .masked-image',
		);
		const lightboxWrappers = info.querySelectorAll(
			'.carousel-lightbox-wrapper',
		);

		const infoTl = gsap.timeline({
			scrollTrigger: {
				trigger: info,
				start: '20% center',
			},
		});

		if (infoImage2) {
			infoTl.to(infoImage2, {
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			});
		}

		if (infoImage1) {
			infoTl.to(
				infoImage1,
				{
					duration: 1,
					transform: 'translateY(0px)',
					opacity: 1,
				},
				'-=0.25',
			);
		}

		//Lightbox Functionality (Stripped down version of CollectionCarousels)
		// This is much closer to a generic Lightbox Component

		[
			infoImage1?.querySelector('a'),
			infoImage2?.querySelector('a'),
		].forEach((image) => {
			image?.addEventListener('click', (e) => {
				const targetedShowcase = e.currentTarget.getAttribute(
					'data-lightbox-toggle-target',
				);

				if (targetedShowcase !== 'false' && targetedShowcase !== null) {
					e.preventDefault();
					lightBoxToggle(targetedShowcase);
				}
			});
		});

		const allSlides = [];
		const allCloseLightboxBtns = [];

		lightboxWrappers?.forEach((showcase) => {
			if (!showcase) return;

			const showcaseTarget = showcase.getAttribute('data-lightbox');

			const closeLightboxBtn = showcase.querySelector(
				`.carousel-lightbox--close-button a`,
			);

			const showcaseKey = showcase.getAttribute('data-lightbox');

			allSlides.push(
				showcase.querySelectorAll(`.carousel-lightbox--slide`),
			);

			allCloseLightboxBtns.push(closeLightboxBtn);

			closeLightboxBtn?.addEventListener('click', (e) => {
				e.preventDefault();
				lightBoxToggle(showcaseKey);
			});

			showcase.addEventListener('click', (e) => {
				if (
					e.target == showcase ||
					e.target ==
						showcase.querySelector(
							'.carousel-lightbox--content-wrapper',
						)
				) {
					lightBoxToggle(showcaseTarget);
				}
			});
		});

		const carouselButtons = info.querySelectorAll('.carousel-control');
		carouselButtons?.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				const targetedShowcase = e.currentTarget.getAttribute(
					'data-lightbox-target',
				);
				const currentSlide = info.querySelector(
					`.carousel-lightbox-wrapper[data-lightbox="${targetedShowcase}"] .carousel-lightbox--slide.active`,
				);
				const slideLength = allSlides[targetedShowcase].length;
				const slideLengthArrayLength =
					slideLength == 0 ? 0 : slideLength - 1;

				let currentIndex = Array.from(
					allSlides[targetedShowcase],
				).indexOf(currentSlide);

				if (btn.classList.contains('prev')) {
					if (currentIndex == 0) {
						currentIndex = slideLengthArrayLength;
					} else {
						currentIndex--;
					}
				}

				if (btn.classList.contains('next')) {
					if (currentIndex == slideLengthArrayLength) {
						currentIndex = 0;
					} else {
						currentIndex++;
					}
				}

				changeSlide(currentIndex, currentSlide, targetedShowcase);
			});
		});

		const changeSlide = (currentIndex, currentSlide, showcaseKey) => {
			const slideTarget = currentIndex;
			const currentLightBoxSlide = info.querySelector(
				`.carousel-lightbox-wrapper[data-lightbox="${showcaseKey}"] .carousel-lightbox--slide.active`,
			);

			if (
				allSlides[showcaseKey][slideTarget] === undefined ||
				(allSlides[showcaseKey][slideTarget] &&
					allSlides[showcaseKey][slideTarget].classList.contains(
						'active',
					))
			) {
				return;
			}

			currentSlide.classList.add('previous');

			gsap.timeline()
				.fromTo(
					currentLightBoxSlide,
					{ transform: 'scale(1)', opacity: 1 },
					{
						transform: 'scale(1.01)',
						duration: 0.5,
						opacity: 0,
						onComplete: () => {
							currentLightBoxSlide.classList.remove('active');
							allSlides[showcaseKey][slideTarget].classList.add(
								'active',
							);
						},
					},
				)
				.fromTo(
					allSlides[showcaseKey][slideTarget],
					{ transform: 'scale(1.01)', opacity: 0 },
					{
						transform: 'scale(1)',
						duration: 0.5,
						opacity: 1,
					},
				);
		};

		const lightBoxToggle = (showcaseKey = null) => {
			const lightboxWrapper = lightboxWrappers[showcaseKey];

			if (lightboxWrapper.classList.contains('animating')) {
				return;
			}

			lightboxWrapper.classList.add('animating');

			if (lightboxWrapper.classList.contains('active')) {
				gsap.fromTo(
					lightboxWrapper,
					{
						display: 'flex',
						opacity: 1,
					},
					{
						duration: 0.75,
						display: 'none',
						opacity: 0,
						onComplete: () => {
							lightboxWrapper.classList.toggle('active');
							lightboxWrapper.classList.remove('animating');
						},
					},
				);
			} else {
				lightboxWrapper.classList.toggle('active'); //For instant z-index manipulation
				gsap.fromTo(
					lightboxWrapper,
					{
						opacity: 0,
						display: 'none',
					},
					{
						delay: 0.2,
						duration: 0.5,
						display: 'flex',
						opacity: 1,
						onComplete: () => {
							lightboxWrapper.classList.remove('animating');
						},
					},
				);
			}

			document.querySelector('body').classList.toggle('disable-scroll');
		};

		return () => {
			// cleanup code (optional)
		};
	}, [FlorzInfo]);

	return (
		<div
			ref={FlorzInfo}
			className={cn(
				'introduction',
				'standard-container',
				reducedPadding && 'reduced-padding',
				wallpaper && 'has-wallpaper',
				accentImage && 'overlay-indent',
			)}
		>
			{accentPattern && (
				<div
					className="herringbone-accent"
					style={{
						backgroundImage: `url(${accentPattern.src})`,
					}}
				></div>
			)}

			{wallpaper && (
				<div
					className="introduction-wallpaper"
					style={{
						backgroundImage: `url(${wallpaper.src})`,
					}}
				></div>
			)}

			{[featuredImage1, featuredImage2].map((lightbox, showkey) => {
				return (
					<div
						className="carousel-lightbox-wrapper"
						data-lightbox={showkey}
						key={showkey}
					>
						<div className="carousel-lightbox--close-button">
							<a href="#" data-lightbox={showkey}>
								<span></span>
								<p className="visually-hidden">Close</p>
								<span></span>
							</a>
						</div>
						<div className="carousel-lightbox--content-wrapper">
							<a
								href="#"
								className="carousel-lightbox--control carousel-control prev"
								data-lightbox-target={showkey}
							>
								<span className="visually-hidden">Prev</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
							<div className="carousel-lightbox--slides">
								{lightbox?.lightbox?.map((slide, index) => {
									return (
										<div
											className={cn(
												'carousel-lightbox--slide',
												index === 0 && 'active',
											)}
											data-target={index}
											data-showcase-target={showkey}
											key={index}
										>
											<AdvancedImage
												image={slide.image}
											></AdvancedImage>
											<p>
												{slide.pretextLabel || `Shown:`}{' '}
												{slide.caption}
											</p>
										</div>
									);
								})}
							</div>
							<a
								href="#"
								className="carousel-lightbox--control carousel-control next"
								data-lightbox-target={showkey}
							>
								<span className="visually-hidden">Next</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5 16H27"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18 7L27 16L18 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
						</div>
					</div>
				);
			})}
			{accentImage && (
				<div className="introduction--overlay-indent">
					{accentImage && (
						<Image
							{...accentImage}
							className="accent-image"
						></Image>
					)}
				</div>
			)}
			<div className="standard-row">
				<div className="introduction--content-wrapper">
					{title && <h2>{title}</h2>}
					{description && <p>{description}</p>}

					<ul className="highlight-list numbers">
						{highlights.map((highlight, index) => {
							return (
								<li key={index}>
									<div className="highlight-row">
										{highlight.icon &&
											!highlight.highlightText && (
												<Image
													{...highlight.icon}
												></Image>
											)}
										{highlight.highlightText && (
											<span className="highlight-number stat-num">
												{highlight.highlightText}
											</span>
										)}

										{highlight.caption && (
											<p className="highlight-caption">
												{highlight.caption}
											</p>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="introduction--image-abstract-grid">
					{featuredImage1 && (
						<MaskedDoorImage
							className="offset-image-1"
							willAnimate={true}
							lightboxTarget={
								featuredImage1.lightbox?.length > 0 && '0'
							}
							{...featuredImage1?.image}
						/>
					)}
					<MaskedDoorImage
						className="offset-image-2"
						mask={'edge'}
						willAnimate={true}
						{...featuredImage2?.image}
					/>
				</div>
			</div>
		</div>
	);
};

export default FlorzInfo;
