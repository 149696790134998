import React, { useRef, useLayoutEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap, ScrollTrigger } from 'gsap/all';

export interface ICollectionIntroProps extends IBaseElementProps {
	preTitle?: string;
	italicizedTitle?: string;
	subtitle?: string;
	excerpt?: string;
}

/**
 * CollectionIntro
 *
 * This component is expected to be used above a CollectionCarousel component.
 * Make sure the CollectionCarousel does not have a gradient enabled for a seamless background blend.
 *
 * @icon table-row-before
 * @block
 */

const CollectionIntro: React.FC<ICollectionIntroProps> = ({
	preTitle,
	italicizedTitle,
	subtitle,
	excerpt,
}) => {
	const productIntro = useRef(null);

	useLayoutEffect(() => {
		if (!productIntro.current) {
			return;
		}
		gsap.registerPlugin(ScrollTrigger);

		const productIntroRef = productIntro.current;
		const productIntroTl = gsap.timeline({
			scrollTrigger: {
				trigger: productIntroRef,
				start: '-500 top', // when the top of the trigger hits the top of the viewport
				end: '+=100',
			},
		});

		productIntroTl.to(
			productIntroRef.querySelector('h2'),
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.25',
		);
		productIntroTl.to(
			productIntroRef.querySelector('h3'),
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.5',
		);
		productIntroTl.to(
			productIntroRef.querySelector('p'),
			{
				duration: 1,
				transform: 'translateY(0px)',
				opacity: 1,
			},
			'-=0.5',
		);

		return () => {
			// cleanup code (optional)
		};
	}, [productIntro]);
	return (
		<div ref={productIntro} className="product-intro-head">
			<div className="product-intro-head--header-wrapper">
				{(preTitle || italicizedTitle) && (
					<h2 className="h3 fade-up">
						{preTitle} <i>{italicizedTitle}</i>
					</h2>
				)}
				<h3 className="h4 fade-up">{subtitle}</h3>
				<p className="fade-up">{excerpt}</p>
			</div>
		</div>
	);
};

export default CollectionIntro;
