import React, { useState, useLayoutEffect } from 'react';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import doorMaskWithEdgeSquare from '../../assets/images/florz_floor_door_mask_with_square_edge.png';
import doorMaskWithEdgeFlippedSquare from '../../assets/images/florz_floor_door_mask_with_square_edge-flipped.png';
import doorMaskSquare from '../../assets/images/florz_floor_door_mask_square.png';
import doorMaskWithEdge from '../../assets/images/florz_floor_door_mask_with_edge.png';
import doorMaskWithEdgeFlipped from '../../assets/images/florz_floor_door_mask_with_edge-flipped.png';
import doorMask from '../../assets/images/florz_floor_door_mask.png';

export interface IMaskedDoorImageProps extends IBaseElementProps {
	caption?: string;
	captionUrl?: string;
	/**
	 * @noUI
	 */
	lightboxTarget?: string;
	/**
	 * @noUI
	 */
	lightboxSwatchIndex?: number;
	pretext?: string;
	image?: IImage;
	mask?: 'no-edge' | 'edge' | 'edge-flipped';
	/**
	 * @noUI
	 */
	willAnimate?: boolean;
}

/**
 * A simple block to say Hello
 * @param name
 * @param greeting
 * @param links
 * @constructor
 * @icon admin-site
 * @block
 */
const MaskedDoorImage: React.FC<IMaskedDoorImageProps> = ({
	caption,
	captionUrl,
	lightboxTarget,
	lightboxSwatchIndex,
	mask = 'no-edge',
	image,
	className,
	pretext = 'Shown:',
	willAnimate = false,
}) => {
	if (!image) {
		return null;
	}

	const [maskedImage, setMaskedImage] = useState(doorMask);

	useLayoutEffect(() => {
		const layoutTheme = document.querySelector(
			'.florz-landing-page',
		) as HTMLElement;

		if (layoutTheme && layoutTheme.classList.contains('smartdrop')) {
			setMaskedImage(
				mask == 'edge'
					? doorMaskWithEdgeSquare
					: mask == 'edge-flipped'
					? doorMaskWithEdgeFlippedSquare
					: doorMaskSquare,
			);

			return;
		}

		if (layoutTheme && layoutTheme.classList.contains('livingwood')) {
			setMaskedImage(doorMaskSquare);
			return;
		}

		if (layoutTheme && layoutTheme.classList.contains('florz')) {
			setMaskedImage(doorMaskSquare);
			return;
		}

		setMaskedImage(
			mask == 'edge'
				? doorMaskWithEdge
				: mask == 'edge-flipped'
				? doorMaskWithEdgeFlipped
				: doorMask,
		);
	}, []);

	return (
		<div
			className={cn(
				willAnimate && 'will-animate',
				'masked-image-container',
				className,
				mask == 'edge-flipped' && 'flipped-mask',
			)}
		>
			<div className="masked-image-shell">
				<div
					className="masked-image"
					style={{
						maskImage: `url(${maskedImage})`,
						WebkitMaskImage: `url(${maskedImage})`,
						maskRepeat: 'no-repeat',
						WebkitMaskRepeat: 'no-repeat',
						maskPosition: 'center',
						WebkitMaskPosition: 'center',
						maskSize: '100%',
						WebkitMaskSize: '100%',
					}}
				>
					{captionUrl || lightboxTarget ? (
						<a
							data-lightbox-toggle-target={lightboxTarget}
							data-swatch-target={lightboxSwatchIndex}
							href={captionUrl}
						>
							<Image {...image} />
						</a>
					) : (
						<Image {...image} />
					)}
				</div>
			</div>
			{caption && (
				<p className="caption">
					{pretext}{' '}
					{captionUrl || lightboxTarget ? (
						<a
							data-lightbox-toggle-target={lightboxTarget}
							href={captionUrl}
						>
							{caption}
						</a>
					) : (
						<span>{caption}</span>
					)}
				</p>
			)}
		</div>
	);
};

export default MaskedDoorImage;
