import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import AdvancedImage from '../AdvancedImage';
import { gsap, ScrollTrigger } from 'gsap/all';

export interface IAlternatingTextImageProps extends IBaseElementProps {
	title?: string;
	rows: IAlternatingTextImageRow[];
}

export interface IAlternatingTextImageRow {
	title?: string;
	excerpt?: string;
	image?: IImage;
}

/**
 * Florz Alternating Text & Image
 *
 * This the new version of the Alternating Text & Image component with floating bubbles.
 *
 * @icon editor-outdent
 * @block
 */
const AlternatingTextImage: React.FC<IAlternatingTextImageProps> = ({
	rows = [],
	title,
}) => {
	const alternatingContentRef = useRef(null);
	// 1st gets class left, 2nd center, 3rd right, 4th left.

	useEffect(() => {
		if (!alternatingContentRef.current) {
			return;
		}
		const alternatingContent = alternatingContentRef.current;
		gsap.registerPlugin(ScrollTrigger);
		const alternatingTextItems =
			alternatingContent.querySelectorAll('.aac-content-row');

		if (alternatingTextItems && alternatingTextItems.length > 0) {
			alternatingTextItems.forEach((item) => {
				const itemImg = item.querySelector(
					'.aac-content-row--image-wrapper',
				);
				const itemHeader = item.querySelector(
					'.aac-content-row--info-wrapper h3',
				);
				const itemText = item.querySelector(
					'.aac-content-row--info-wrapper p',
				);

				gsap.fromTo(
					itemHeader,
					{ transform: 'translateY(50px)', opacity: 0 },
					{
						duration: 1,
						transform: 'translateY(0px)',
						opacity: 1,
						scrollTrigger: {
							trigger: item,
						},
					},
				);

				gsap.fromTo(
					itemText,
					{ transform: 'translateY(50px)', opacity: 0 },
					{
						duration: 1,
						delay: 0.25,
						transform: 'translateY(0px)',
						opacity: 1,
						scrollTrigger: {
							trigger: item,
						},
					},
				);
			});
		}
		return () => {
			// cleanup code (optional)
		};
	}, [alternatingContentRef]);

	let positionIndex = 0;
	let positions = ['left', 'center', 'right'];
	return (
		<div
			ref={alternatingContentRef}
			className="abstract-alternating-content"
		>
			<div className="abstract-alternating-content--content-wrapper">
				{title && <h2>{title}</h2>}
				<div className="aac-content-listings">
					{rows.map((row, index) => {
						return (
							<div
								className={cn(`aac-content-row`, 'center')}
								key={index}
							>
								<div className="aac-content-row--content">
									<div className="aac-content-row--image-wrapper">
										<AdvancedImage
											image={row.image}
										></AdvancedImage>
									</div>
									<div className="aac-content-row--info-wrapper">
										{row.title && <h3>{row.title}</h3>}
										{row.excerpt && <p>{row.excerpt}</p>}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AlternatingTextImage;
