import React, { AnchorHTMLAttributes } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Icon from 'tbk-components/src/components/Icon';

export interface ILink {
	href?: string;
	label?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: any;
}

export interface IButtonProps
	extends IBaseElementProps,
		AnchorHTMLAttributes<HTMLAnchorElement> {
	type?: 'inherit' | 'primary' | 'secondary';
	size?: 'sm' | 'md' | 'lg';
	onClick?: (event: any) => any;
	icon?: string;
	iconPosition?: 'before' | 'after';
	showIcon?: boolean;
	href?: string;
	label?: string;
	caption?: string;
}
export interface IButtonBlockProps extends IBaseElementProps {
	caption?: string;
	children?: any;
}

const ButtonWrapper: React.FC<IButtonBlockProps> = ({ caption, children }) => {
	if (caption) {
		return (
			<div className="button-block">
				{children}
				{caption && <span className="button-caption">{caption}</span>}
			</div>
		);
	}

	return children;
};

/**
 * Button
 * @icon button
 * @block
 */

const Button: React.FC<IButtonProps> = ({
	type = 'inherit',
	size = 'md',
	label,
	children,
	className,
	showIcon = true,
	classNames = [],
	href,
	icon = 'arrow-right',
	iconPosition = 'before',
	caption,
	...props
}) => {
	if (icon == 'arrow-right') {
		iconPosition = 'after';
	}

	const btnProps = {
		className:
			className ||
			cn(
				'btn',
				`btn-${type}`,
				`btn-${size}`,
				iconPosition == 'after' ? 'reversed' : '',
				...classNames,
			),
		...props,
	};

	if (!href || !btnProps.target || btnProps.target === '_self') {
		delete btnProps.target;
	}

	return href ? (
		<ButtonWrapper caption={caption}>
			<a href={href} {...btnProps}>
				{showIcon && icon && <Icon name={icon} />}
				{label}
				{children}
			</a>
		</ButtonWrapper>
	) : (
		<ButtonWrapper caption={caption}>
			{/* @ts-ignore */}
			<button type="button" {...btnProps}>
				{icon && <Icon name={icon} />}
				{label}
				{children}
			</button>
		</ButtonWrapper>
	);
};

export default Button;
