import React, { useRef, useEffect } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { gsap } from 'gsap/all';
import cn from 'classnames';
import Image, { IImage } from 'tbk-components/src/components/Image';
import AdvancedImage from '../AdvancedImage';
import Button, { ILink } from '../Button';
import doorMask from '../../assets/images/florz_floor_door_mask.png';
import Video, { IVideo } from 'tbk-components/src/components/Video';

export interface IFlorzHeroProps extends IBaseElementProps {
	eyebrow?: string;
	eyebrowLogo?: IImage;
	/*
	 * This title gets split up into different styles.
	 */
	title?: IFlorzHeroTitle;
	/*
	 * This will play a small intro animation to the webpage.
	 */
	playIntro?: boolean;
	excerpt?: string;
	cta?: ILink;
	/*
	 * This video will be used as the background video.
	 * It is also mandatory to upload a poster image as the video will be disabled for mobile.
	 */
	video?: IVideo;
	poster?: IImage;

	/*
	 * Expected to be an oEmbed code that will pass into IVideo
	 */
	videoOembed?: string;
	playButtonLabel?: string;
	/*
	 * These are used for Living Wood to show different wood carvings
	 */
	accentImage?: IImage;
	accentImage2?: IImage;
}

export interface IFlorzHeroTitle {
	top?: string;
	middle?: string;
	bottom?: string;
}

/**
 * FlorzHero
 *
 * This should be the first component after the Header inside of an FlorzLandingPage.
 *
 * @icon welcome-view-site
 * @block
 */

const FlorzHero: React.FC<IFlorzHeroProps> = ({
	eyebrow,
	eyebrowLogo,
	title,
	excerpt,
	cta,
	video,
	poster,
	playIntro = false,
	videoOembed,
	playButtonLabel,
	accentImage,
	accentImage2,
}) => {
	const hero = useRef(null);

	useEffect(() => {
		if (!hero.current || playIntro === false) {
			return;
		}

		const heroRef = hero.current;

		//Push window to the top for consistent intro animation
		window.scrollTo(0, 0);

		//Look to see if <Header> exists.
		const header = document.querySelector('.florz-landing-header');

		document.querySelector('body').classList.add('disable-scroll');
		// Hero animation
		const tl = gsap.timeline();
		tl.to(heroRef.querySelector('.landing-page-hero--intro-text'), {
			duration: 1,
			opacity: 1,
			delay: 0.25,
		})
			.to(
				heroRef.querySelector('.door-image-mask-wrapper'),
				{
					duration: 1,
					webkitMaskSize: '200px',
				},
				'-=1',
			)
			.to(heroRef.querySelector('.landing-page-hero--intro-text'), {
				duration: 1,
				opacity: 0,
				delay: 0.5,
			})
			.to(
				heroRef.querySelector('.door-image-mask-wrapper'),
				{
					duration: 2,
					webkitMaskSize: '200%',
					delay: 1,
				},
				'-=1',
			)
			.to(
				heroRef.querySelector('.hero-image--overlay'),
				{
					duration: 0.5,
					opacity: 1,
				},
				'-=1.25',
			)

			.to(
				heroRef.querySelector('.landing-page-hero--content-wrapper'),
				{
					duration: 1,
					opacity: 1,
					onComplete: () => {
						document
							.querySelector('body')
							.classList.remove('disable-scroll');
					},
				},
				'<',
			);

		if (header) {
			tl.fromTo(
				header,
				{
					opacity: 0,
				},
				{
					duration: 0.5,
					opacity: 1,
				},
				'-=0.75',
			);
		}

		//End Hero animation

		return () => {
			// cleanup code (optional)
		};
	}, [hero]);
	return (
		<div
			ref={hero}
			className={cn('landing-page-hero', !playIntro && 'no-intro')}
		>
			<div className="landing-page-hero--intro-text">
				{eyebrow && <span>{eyebrow}</span>}
			</div>
			<div className="landing-page-hero--content-wrapper">
				{eyebrowLogo && (
					<Image
						{...eyebrowLogo}
						className="landing-page-hero--intro-text-logo"
					/>
				)}
				{eyebrow && <h6 className="leading-title">{eyebrow}</h6>}
				<div className="title-wrapper">
					<h1 className="hero-title">
						{title?.top && (
							<span className="hero-title--eyebrow subheading">
								{title.top}
							</span>
						)}
						{title?.middle && (
							<span className="hero-title--title h1-large">
								{title.middle}
							</span>
						)}
						{title?.bottom && (
							<span className="hero-title--subtitle h4">
								{title.bottom}
							</span>
						)}
					</h1>
				</div>
				<div className="excerpt-wrapper">
					{excerpt && <p className="subheading">{excerpt}</p>}
					{cta && <Button {...cta} />}
				</div>
				{videoOembed && (
					<Video oembed={videoOembed} playLabel={playButtonLabel} />
				)}
			</div>
			{(accentImage || accentImage2) && (
				<div className="landing-page-hero--accent-overlays">
					{accentImage && (
						<Image {...accentImage} className="accent-image-1" />
					)}
					{accentImage2 && (
						<Image {...accentImage2} className="accent-image-2" />
					)}
				</div>
			)}
			<div className={cn('landing-page-hero--image-wrapper')}>
				<div className="hero-image--overlay"></div>
				<div
					className={cn(
						'door-image-mask-wrapper',
						video && 'has-video',
					)}
					style={{
						maskImage: `url(${doorMask})`,
						WebkitMaskImage: `url(${doorMask})`,
						maskRepeat: 'no-repeat',
						WebkitMaskRepeat: 'no-repeat',
						maskPosition: 'center',
						WebkitMaskPosition: 'center',
						maskSize: playIntro ? '0%' : '200%',
						WebkitMaskSize: playIntro ? '0%' : '200%',
					}}
				>
					{video?.src && (
						<video loop autoPlay muted>
							<source src={video.src} type="video/mp4"></source>
						</video>
					)}
					{poster && <AdvancedImage image={poster}></AdvancedImage>}
				</div>
			</div>
		</div>
	);
};

export default FlorzHero;
