import React, { useEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from '../Button';

export interface IHeaderProps extends IBaseElementProps {
	homeLink?: ILink;
	logoImage?: IImage;
	enPageHref?: string;
	frPageHref?: string;
}

export interface IHeaderRow {
	title?: string;
	excerpt?: string;
	image?: IImage;
}

/**
 * Header
 *
 * This should be the first component inside the FlorzLandingPage. This is the main navigation to the page.
 *
 * @icon align-center
 * @block
 */
const Header: React.FC<IHeaderProps> = ({
	homeLink,
	logoImage,
	enPageHref,
	frPageHref,
}) => {
	const lang = 'en'; //This will need to be wired in B/E eventually
	const header = useRef(null);
	useEffect(() => {
		//Look to see if <Hero> exists.
		//Todo: Look to see if <Hero> has animation enabled.

		if (!header.current) {
			return;
		}

		const hero = document.querySelector('.landing-page-hero');

		//This is hidden by default to prevent a mount flicker
		//If hero and animation is enabled, that means this header will fade in eventually.
		//If not, we need to make the header visible.
		if (!hero || hero.classList.contains('no-intro')) {
			header.current.classList.add('visible');
		}

		return () => {
			// cleanup code (optional)
		};
	}, []);
	return (
		<header ref={header} className="florz-landing-header">
			<div className="florz-landing-header--content-wrapper">
				{homeLink && (
					<a href={homeLink.href} className="back-button-wrapper">
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27 16H5"
								stroke="#E3D9C6"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M14 7L5 16L14 25"
								stroke="#E3D9C6"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>{homeLink.label}</span>
					</a>
				)}
				<div className="logo-wrapper">
					<Image {...logoImage}></Image>
				</div>
				<div className="language-switcher-container">
					<ul>
						{enPageHref && (
							<li>
								<a
									href={enPageHref}
									className={lang == 'en' ? 'active' : ''}
								>
									En
								</a>
							</li>
						)}
						{frPageHref && (
							<li>
								<a
									href={frPageHref}
									/* @ts-ignore */
									className={lang == 'fr' ? 'active' : ''}
								>
									Fr
								</a>
							</li>
						)}
					</ul>
				</div>
			</div>
		</header>
	);
};

export default Header;
