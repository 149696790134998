import React, {
	useMemo,
	useEffect,
	useLayoutEffect,
	useState,
	useRef,
} from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Tab from 'tbk-components/src/components/Tab';
import Tabs from 'tbk-components/src/components/Tabs';
import { gsap, ScrollTrigger } from 'gsap/all';
import AdvancedImage from '../AdvancedImage';

import doorMaskWithEdgeSquare from '../../assets/images/florz_floor_door_mask_with_square_edge.png';
import doorMaskWithEdgeFlippedSquare from '../../assets/images/florz_floor_door_mask_with_square_edge-flipped.png';
import doorMaskWithEdge from '../../assets/images/florz_floor_door_mask_with_edge.png';
import doorMaskWithEdgeFlipped from '../../assets/images/florz_floor_door_mask_with_edge-flipped.png';
import doorMaskSquare from '../../assets/images/florz_floor_door_mask_square.png';
import { ILink } from '../Button';

export interface ICollectionCarouselsProps extends IBaseElementProps {
	/**
	 * @control uniqueid
	 * @prefix collection-carousel
	 */
	id?: string;
	title?: string;
	excerpt?: string;
	cta?: ILink;
	headerLogo?: IImage;
	showcases?: IShowcase[];
	specificationTitle?: string;
	specifications?: ISpecification[];
	flipped?: boolean;
	patternWallpaper?: IImage;
	patterns?: IPattern[];
	gradient?: boolean;
	logos?: IImage[];
}

export interface ISpecification {
	measurement?: string;
	spec?: string;
}

//A collection can have multiple showcases.
//Each showcase will have its own swatch tab, its own carousel, and lightbox.
export interface IShowcase {
	name?: string;
	excerpt?: string;
	logo?: IImage;
	specSheetPdfLabel?: string;
	specSheetPdfUrl?: string;
	products?: IProduct[];
}

//This is the carousel is made from.
export interface IProduct {
	name?: string;
	image?: IImage;
	caption?: string;
	swatch?: ISwatch;
	pretextLabel?: string;
}

//These are just accented labels for pattern options.
//These do not affect IShowcase
export interface IPattern {
	name?: string;
	icon?: IImage;
}

//Swatch icons
export interface ISwatch {
	name?: string;
	caption?: string;
	icon?: IImage;
}

/**
 * CollectionCarousels
 *
 * This component is used to display a collection of products in a carousel.
 * Each collection can have multiple showcases.
 *
 * @icon table-col-after
 * @block
 */

const CollectionCarousels: React.FC<ICollectionCarouselsProps> = ({
	id,
	title,
	excerpt,
	headerLogo,
	cta,
	showcases = [],
	specificationTitle,
	patternWallpaper,
	specifications = [],
	patterns = [],
	flipped = false,
	gradient = false,
	logos = [],
}) => {
	const carousel = useRef(null);
	const randomKey = useMemo(
		() => (id ? id : (Math.random() + 1).toString(36).substring(7)),
		[id],
	);
	const [maskedImage, setMaskedImage] = useState(
		flipped ? doorMaskWithEdge : doorMaskWithEdgeFlipped,
	);

	useLayoutEffect(() => {
		const layoutTheme = document.querySelector(
			'.florz-landing-page',
		) as HTMLElement;
		flipped ? doorMaskWithEdge : doorMaskWithEdgeFlipped;
		if (layoutTheme && layoutTheme.classList.contains('smartdrop')) {
			setMaskedImage(
				flipped
					? doorMaskWithEdgeSquare
					: doorMaskWithEdgeFlippedSquare,
			);

			return;
		}

		if (layoutTheme && layoutTheme.classList.contains('livingwood')) {
			setMaskedImage(doorMaskSquare);
			return;
		}

		if (layoutTheme && layoutTheme.classList.contains('florz')) {
			setMaskedImage(doorMaskSquare);
			return;
		}

		setMaskedImage(flipped ? doorMaskWithEdge : doorMaskWithEdgeFlipped);
	}, []);

	useEffect(() => {
		if (!carousel.current) {
			return;
		}
		gsap.registerPlugin(ScrollTrigger);

		if (!showcases?.length) {
			return;
		}

		const ref = carousel.current;
		const swatches = ref.querySelectorAll('.swatches li');
		const swatchButtons = ref.querySelectorAll('.swatches li a');

		// This is a "forced" method to work with/integrate the Tab component.
		// When a tab is switched, monitor the index and update which carousel is visible.
		const carouselShowcases = ref.querySelectorAll(
			'.product-carousel-showcase--canvas-wrapper',
		);

		const swatchTabs = ref.querySelectorAll('.tabs .nav-link');
		swatchTabs.forEach((tab) => {
			tab.addEventListener('click', (e) => {
				const carouselId = [].indexOf.call(swatchTabs, tab);
				const activeCarousel = ref.querySelector(
					'.product-carousel-showcase--canvas-wrapper.active',
				);

				if (
					activeCarousel &&
					carouselShowcases[carouselId] &&
					!carouselShowcases[carouselId].classList.contains('active')
				) {
					carouselShowcases[carouselId].classList.add('active');
					activeCarousel.classList.remove('active');
				}
			});
		});

		//This pushes it all into one array, and based off the index it knows which to target.
		//The result will be something like allSwatchButtons[[3][5]]
		//This whole area can definitely be refactored into using inline @clicks and methods, states, etc.
		const allSwatchButtons = [];
		ref.querySelectorAll('.swatches-wrapper')?.forEach((showcase) => {
			allSwatchButtons.push(showcase.querySelectorAll('.swatches li a'));
		});

		const allDoorImages = [];
		carouselShowcases.forEach((showcase) => {
			allDoorImages.push(
				showcase.querySelectorAll(
					`.carousel-items--door-mask-container .carousel-lightbox-button`,
				),
			);
		});

		const allLightBoxSlides = [];
		ref.querySelectorAll('.carousel-lightbox-wrapper').forEach(
			(showcase) => {
				allLightBoxSlides.push(
					showcase.querySelectorAll(`.carousel-lightbox--slide`),
				);
			},
		);

		const allCaptions = [];
		carouselShowcases.forEach((showcase) => {
			allCaptions.push(
				showcase.querySelectorAll(
					`.carousel-items--captions-container > p`,
				),
			);
		});

		const allCloseLightboxBtns = [];
		ref.querySelectorAll('.carousel-lightbox-wrapper').forEach(
			(showcase) => {
				const closeLightboxBtn = showcase.querySelector(
					`.carousel-lightbox--close-button a`,
				);

				const showcaseKey = showcase.getAttribute('data-lightbox');

				allCloseLightboxBtns.push(closeLightboxBtn);

				closeLightboxBtn.addEventListener('click', (e) => {
					e.preventDefault();
					lightBoxToggle(showcaseKey);
				});
			},
		);

		const lightBoxButtons = ref.querySelectorAll(
			'.carousel-lightbox-button',
		);

		const carouselButtons = ref.querySelectorAll('.carousel-control');

		// - Product Carousel Animation
		const FlorzShowcaseTl = gsap.timeline({
			scrollTrigger: {
				trigger: ref,
				start: 'top 5%',
			},
		});

		FlorzShowcaseTl.fromTo(
			allSwatchButtons.length > 0 ? allSwatchButtons[0] : swatches,
			{
				transform: 'translateX(22px)',
				opacity: 0,
			},
			{
				duration: 1,
				transform: 'translateX(0px)',
				opacity: 1,
				stagger: {
					each: 0.25,
				},
			},
			'-=0.5',
		);
		// - End Product Carousel Animation

		swatchButtons.forEach((swatch) => {
			swatch.addEventListener('click', (e) => {
				e.preventDefault();

				const targetedShowcase = e.currentTarget.getAttribute(
					'data-showcase-target',
				);

				const currentSlide = ref.querySelector(
					`.product-carousel-showcase--canvas-wrapper[data-carousel="${targetedShowcase}"] .carousel-items--door-mask-container .carousel-lightbox-button.active`,
				);

				changeSwatch(swatch, currentSlide, targetedShowcase);
			});
		});

		lightBoxButtons.forEach((lightboxButton) => {
			lightboxButton.addEventListener('click', (e) => {
				e.preventDefault();
				const targetedShowcase = e.currentTarget.getAttribute(
					'data-showcase-target',
				);
				lightBoxToggle(targetedShowcase);
			});
		});

		const lightboxWrappers = ref.querySelectorAll(
			'.carousel-lightbox-wrapper',
		);

		lightboxWrappers.forEach((lightboxWrapper) => {
			const showcaseTarget =
				lightboxWrapper.getAttribute('data-lightbox');
			lightboxWrapper.addEventListener('click', (e) => {
				if (
					e.target == lightboxWrapper ||
					e.target ==
						lightboxWrapper.querySelector(
							'.carousel-lightbox--content-wrapper',
						)
				) {
					lightBoxToggle(showcaseTarget);
				}
			});
		});

		const lightBoxToggle = (showcaseKey = null) => {
			const lightboxWrapper = lightboxWrappers[showcaseKey];

			if (lightboxWrapper.classList.contains('animating')) {
				return;
			}

			lightboxWrapper.classList.add('animating');

			if (lightboxWrapper.classList.contains('active')) {
				gsap.fromTo(
					lightboxWrapper,
					{
						display: 'flex',
						opacity: 1,
					},
					{
						duration: 0.75,
						display: 'none',
						opacity: 0,
						onComplete: () => {
							lightboxWrapper.classList.toggle('active');
							lightboxWrapper.classList.remove('animating');
						},
					},
				);
			} else {
				lightboxWrapper.classList.toggle('active'); //For instant z-index manipulation
				gsap.fromTo(
					lightboxWrapper,
					{
						opacity: 0,
						display: 'none',
					},
					{
						delay: 0.2,
						duration: 0.5,
						display: 'flex',
						opacity: 1,
						onComplete: () => {
							lightboxWrapper.classList.remove('animating');
						},
					},
				);
			}

			document.querySelector('body').classList.toggle('disable-scroll');
		};

		carouselButtons.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				const targetedShowcase = e.currentTarget.getAttribute(
					'data-lightbox-target',
				);
				const currentSlide = ref.querySelector(
					`.product-carousel-showcase--canvas-wrapper[data-carousel="${targetedShowcase}"] .carousel-items--door-mask-container .carousel-lightbox-button.active`,
				);
				const slideLength = allDoorImages[targetedShowcase].length;
				const slideLengthArrayLength =
					slideLength == 0 ? 0 : slideLength - 1;

				let currentIndex = Array.from(
					allDoorImages[targetedShowcase],
				).indexOf(currentSlide);

				if (btn.classList.contains('prev')) {
					if (currentIndex == 0) {
						currentIndex = slideLengthArrayLength;
					} else {
						currentIndex--;
					}
				}

				if (btn.classList.contains('next')) {
					if (currentIndex == slideLengthArrayLength) {
						currentIndex = 0;
					} else {
						currentIndex++;
					}
				}

				changeSwatch(
					allSwatchButtons[targetedShowcase][currentIndex],
					currentSlide,
					targetedShowcase,
				);
			});
		});

		const changeSwatch = (swatch, currentSlide, showcaseKey) => {
			const swatchTarget = swatch.getAttribute('data-target');
			const currentCaption = ref.querySelector(
				`.product-carousel-showcase--canvas-wrapper[data-carousel="${showcaseKey}"] .carousel-items--captions-container > p.active`,
			);
			const currentSwatch = ref.querySelector(
				`.swatches-wrapper[data-target="${showcaseKey}"] .swatches li a.active`,
			);
			const currentLightBoxSlide = ref.querySelector(
				`.carousel-lightbox-wrapper[data-lightbox="${showcaseKey}"] .carousel-lightbox--slide.active`,
			);

			if (
				allDoorImages[showcaseKey][swatchTarget] === undefined ||
				(allDoorImages[showcaseKey][swatchTarget] &&
					allDoorImages[showcaseKey][swatchTarget].classList.contains(
						'active',
					)) ||
				currentCaption == null
			) {
				return;
			}

			currentSlide.classList.add('previous');
			currentSlide.classList.remove('active');
			currentSwatch.classList.remove('active');
			currentCaption.classList.remove('active');
			swatch.classList.add('active');
			allDoorImages[showcaseKey][swatchTarget].classList.add('active');

			gsap.timeline()
				.fromTo(
					currentLightBoxSlide,
					{ transform: 'scale(1)', opacity: 1 },
					{
						transform: 'scale(1.01)',
						duration: 0.5,
						opacity: 0,
						onComplete: () => {
							currentLightBoxSlide.classList.remove('active');
							allLightBoxSlides[showcaseKey][
								swatchTarget
							].classList.add('active');
						},
					},
				)
				.fromTo(
					allLightBoxSlides[showcaseKey][swatchTarget],
					{ transform: 'scale(1.01)', opacity: 0 },
					{
						transform: 'scale(1)',
						duration: 0.5,
						opacity: 1,
					},
				);

			gsap.fromTo(
				allDoorImages[showcaseKey][swatchTarget],
				{ transform: 'scale(1.1)', opacity: 0 },
				{
					transform: 'scale(1)',
					duration: 0.75,
					opacity: 1,
					onComplete: () => {
						currentSlide.classList.remove('previous');
						allCaptions[showcaseKey][swatchTarget].classList.add(
							'active',
						);
					},
				},
			);
		};

		return () => {
			// cleanup code (optional)
		};
	}, [carousel]);

	const toggleSpecs = (e) => {
		e.preventDefault();
		if (!carousel.current) {
			return;
		}

		const accordion = carousel.current.querySelector('.specifications-row');
		const accordionTarget = accordion.querySelector(
			'.specifications-row--results',
		);

		if (accordionTarget.classList.contains('animating')) {
			return;
		}

		if (accordion.classList.contains('active')) {
			accordion.classList.remove('active');
			return;
		}

		accordion.classList.add('active');
		accordionTarget.classList.add('animating');

		const listEls = accordionTarget.querySelectorAll(
			'.specifications-row--listing > li',
		);
		gsap.fromTo(
			listEls,
			{ y: -20, opacity: 0 },
			{
				y: 0,
				duration: 0.25,
				opacity: 1,
				stagger: {
					each: 0.05,
				},
				onComplete: () => {
					accordionTarget.classList.remove('animating');
				},
			},
		);
	};

	return (
		<div
			ref={carousel}
			id={id}
			className={cn(
				`product-carousel-showcase`,
				gradient && `with-gradient`,
			)}
		>
			{patternWallpaper && (
				<div
					className="herringbone-accent"
					style={{
						backgroundImage: `url(${patternWallpaper.src})`,
					}}
				></div>
			)}
			{showcases?.map((lightbox, showkey) => {
				return (
					<div
						className="carousel-lightbox-wrapper"
						data-lightbox={showkey}
						key={showkey}
					>
						<div className="carousel-lightbox--close-button">
							<a href="#" data-lightbox={showkey}>
								<span></span>
								<p className="visually-hidden">Close</p>
								<span></span>
							</a>
						</div>
						<div className="carousel-lightbox--content-wrapper">
							<a
								href="#"
								className="carousel-lightbox--control carousel-control prev"
								data-lightbox-target={showkey}
							>
								<span className="visually-hidden">Prev</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 16H5"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14 7L5 16L14 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
							<div className="carousel-lightbox--slides">
								{lightbox?.products?.map((slide, index) => {
									return (
										<div
											className={cn(
												'carousel-lightbox--slide',
												index === 0 && 'active',
											)}
											data-target={index}
											data-showcase-target={showkey}
											key={index}
										>
											<AdvancedImage
												image={slide.image}
											></AdvancedImage>
											<p>
												{slide.pretextLabel || 'Shown:'}{' '}
												{slide.caption}
											</p>
										</div>
									);
								})}
							</div>
							<a
								href="#"
								className="carousel-lightbox--control carousel-control next"
								data-lightbox-target={showkey}
							>
								<span className="visually-hidden">Next</span>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5 16H27"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18 7L27 16L18 25"
										stroke="#E3D9C6"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
						</div>
					</div>
				);
			})}
			<div
				className={cn(
					'product-carousel-showcase--carousel-wrapper',
					flipped && 'flipped',
				)}
			>
				<div className="showcase-carousels">
					{showcases?.map((showcase, showcaseKey) => {
						return (
							<div
								data-carousel={showcaseKey}
								className={cn(
									`product-carousel-showcase--canvas-wrapper`,
									showcaseKey === 0 && 'active',
								)}
								key={showcaseKey}
							>
								<div className="carousel-arrows">
									<a
										href="#"
										data-showcase-target={showcaseKey}
										className="carousel-control prev"
										data-lightbox-target={showcaseKey}
									>
										<span className="visually-hidden">
											Prev
										</span>
										<svg
											width="68"
											height="56"
											viewBox="0 0 68 56"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M30.7228 0.0599292C38.6912 0.3173 46.7868 1.8793 52.5407 7.51284C58.4007 13.2502 61.7568 21.5258 61.0841 29.7861C60.4534 37.5301 55.0032 43.6221 49.1912 48.64C43.9456 53.1689 37.5926 56.0441 30.7228 55.9995C23.898 55.9552 17.7076 52.8903 12.4878 48.4017C6.66602 43.3954 1.152 37.5009 0.239465 29.7861C-0.771873 21.2361 1.3789 12.0061 7.47911 6.0574C13.4865 0.199211 22.4192 -0.208266 30.7228 0.0599292Z"
												fill="#4B6BB7"
											/>
											<path
												d="M32.9664 14.8459C29.2567 20.4426 25.5458 26.0383 21.8361 31.635C21.3113 32.4273 21.5754 33.6648 21.9211 34.4835C21.9598 34.575 22.0725 34.802 22.225 35.0499C22.5509 35.6968 23.0844 36.3304 23.8025 36.1563C30.1272 34.6246 36.4519 33.0929 42.7766 31.5601C43.7488 31.3243 43.6869 29.7573 43.4737 29.04C43.2539 28.2984 42.6098 26.9617 41.6067 27.2052C38.4316 27.9744 35.2566 28.7435 32.0815 29.5127C34.4302 28.1849 36.885 27.0421 39.4182 26.0967C44.9441 24.0339 50.8203 22.8967 56.7197 22.7777C60.0096 22.7115 63.2918 22.9738 66.5288 23.5655C67.3684 23.7187 67.217 22.2476 67.1342 21.7738C67.0347 21.2074 66.8823 20.6234 66.6006 20.1176C66.4448 19.8377 66.1885 19.3121 65.8394 19.2481C59.9555 18.1738 53.8915 18.1418 47.9933 19.1313C42.0895 20.1209 36.3525 22.1176 31.1259 25.03C30.8022 25.2107 30.4808 25.3948 30.1604 25.5821C31.633 23.3606 33.1067 21.1391 34.5794 18.9165C35.1041 18.1242 34.8401 16.8867 34.4943 16.068C34.3706 15.7737 33.4801 14.0735 32.9675 14.8459H32.9664Z"
												fill="white"
											/>
										</svg>
									</a>
									<a
										href="#"
										className="carousel-control next"
										data-lightbox-target={showcaseKey}
									>
										<span className="visually-hidden">
											Next
										</span>
										<svg
											width="64"
											height="56"
											viewBox="0 0 64 56"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M36.0343 0.0711434C43.6447 -0.260255 51.8811 0.393744 57.261 5.77248C62.637 11.1471 63.5604 19.3792 62.9129 26.9435C62.3228 33.8376 58.626 39.6986 53.9216 44.7838C48.957 50.1502 43.3539 55.8511 36.0343 55.9973C28.6269 56.1453 23.1647 50.1746 17.4159 45.5131C10.5984 39.985 0.395716 35.7046 0.203679 26.9435C0.0110863 18.157 9.54087 12.8257 16.5836 7.54381C22.3565 3.21427 28.8184 0.385369 36.0343 0.0711434Z"
												fill="#4B6BB7"
											/>
											<path
												d="M24.8088 23.911C24.9434 24.4877 25.1568 25.0854 25.5258 25.5564C25.7406 25.8316 26.1431 26.3603 26.5414 26.3301C29.7503 26.0901 32.9604 25.8499 36.1692 25.6088C29.6109 31.2165 21.3151 34.7825 12.6966 35.7757C9.77315 36.1121 6.82948 36.13 3.90314 35.8379C2.97414 35.7451 3.18143 37.2418 3.31422 37.7686C3.459 38.3434 3.66836 38.9334 4.01441 39.4199C4.22001 39.7096 4.53901 40.1892 4.93042 40.2291C15.7183 41.305 26.7921 38.2174 35.4883 31.8078C36.5151 31.051 37.5062 30.2526 38.4618 29.415C37.477 32.4657 36.0605 35.3774 34.242 38.0632C34.0559 38.3381 34.1497 38.9375 34.193 39.2142C34.2833 39.7854 34.48 40.4026 34.7309 40.9232C34.8654 41.2026 35.6775 42.8141 36.1268 42.151C39.3874 37.3339 41.4057 31.716 41.9781 25.9529C42.0658 25.8557 42.1548 25.7608 42.2413 25.6637C42.4754 25.3986 42.5193 24.9338 42.4396 24.4084C42.5241 24.0188 42.4786 23.559 42.3951 23.199C42.2605 22.6223 42.0471 22.0247 41.6781 21.5536C41.4633 21.2784 41.0608 20.7497 40.6625 20.7799C35.6426 21.1563 30.6225 21.5314 25.6026 21.9078C24.6261 21.9806 24.6339 23.1644 24.8088 23.911Z"
												fill="white"
											/>
										</svg>
									</a>
								</div>
								<div className="carousel-items">
									<div
										className="carousel-items--door-mask-container"
										style={{
											maskImage: `url(${maskedImage})`,
											WebkitMaskImage: `url(${maskedImage})`,
											maskRepeat: 'no-repeat',
											WebkitMaskRepeat: 'no-repeat',
											maskPosition: 'center',
											WebkitMaskPosition: 'center',
											maskSize: 'contain',
											WebkitMaskSize: 'contain',
										}}
									>
										{showcase?.products?.map(
											(product, productKey) => {
												return (
													<a
														href="#"
														className={cn(
															'carousel-lightbox-button',
															productKey === 0 &&
																'active',
														)}
														data-lightbox-target={
															productKey
														}
														data-showcase-target={
															showcaseKey
														}
														key={productKey}
													>
														<AdvancedImage
															image={
																product.image
															}
														></AdvancedImage>
													</a>
												);
											},
										)}
									</div>
									<div className="carousel-items--captions-container">
										{showcase?.products?.map(
											(product, productKey) => {
												return (
													<p
														className={cn(
															productKey === 0 &&
																'active',
														)}
														key={productKey}
													>
														<span>
															{product.pretextLabel ||
																'Shown:'}{' '}
														</span>{' '}
														{product.caption}
													</p>
												);
											},
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="product-carousel-showcase--info-wrapper">
					<div className="info-wrapper--head">
						{title && <h2>{title}</h2>}
						{excerpt && <p>{excerpt}</p>}
						{(headerLogo || cta) && (
							<div className="mb-5 mb-md-8 d-flex align-items-center gap-3">
								{cta && (
									<a
										href={cta.href}
										className="btn btn-primary"
									>
										{cta.label}
									</a>
								)}
								{headerLogo && (
									<Image
										className="carousel-header-logo"
										{...headerLogo}
									></Image>
								)}
							</div>
						)}
						{patterns?.length > 0 && (
							<div className="available-patterns">
								<p>Available Patterns:</p>
								<ul>
									{patterns.map((pattern, patternKey) => {
										return (
											<li key={patternKey}>
												{pattern.icon && (
													<Image
														{...pattern.icon}
													></Image>
												)}
												<span>{pattern.name}</span>
											</li>
										);
									})}
								</ul>
							</div>
						)}
						<div
							className={cn(
								'swatches-tabs-wrapper',
								showcases?.length == 1 && 'single-product',
							)}
						>
							{showcases?.length > 0 && (
								<Tabs>
									{showcases?.map((showcase, showcaseKey) => {
										return (
											<Tab
												id={`${randomKey}-swatch-tab-${showcaseKey}`}
												title={showcase.name}
												key={showcaseKey}
											>
												<>
													<div className="showcase-info">
														{showcase.excerpt && (
															<p>
																{
																	showcase.excerpt
																}
															</p>
														)}
														{showcase.logo && (
															<Image
																{...showcase.logo}
															></Image>
														)}
													</div>
													{showcase.specSheetPdfUrl && (
														<div className="spec-sheet">
															<a
																href={
																	showcase.specSheetPdfUrl
																}
																target="_blank"
																rel="noopener noreferrer"
															>
																{showcase.specSheetPdfLabel
																	? showcase.specSheetPdfLabel
																	: 'View SpecSheet'}
															</a>
														</div>
													)}

													<div
														className="swatches-wrapper"
														data-target={
															showcaseKey
														}
													>
														<ul className="swatches">
															{showcase?.products?.map(
																(
																	product,
																	productKey,
																) => {
																	return (
																		<li
																			key={
																				productKey
																			}
																		>
																			<a
																				href="#"
																				data-target={
																					productKey
																				}
																				data-showcase-target={
																					showcaseKey
																				}
																				className={cn(
																					productKey ===
																						0 &&
																						'active',
																				)}
																			>
																				<div className="swatch-icon-wrapper">
																					{product.swatch &&
																						product
																							.swatch
																							.icon && (
																							<AdvancedImage
																								image={
																									product
																										.swatch
																										.icon
																								}
																							></AdvancedImage>
																						)}
																				</div>
																				<span>
																					{
																						product.name
																					}
																				</span>
																			</a>
																		</li>
																	);
																},
															)}
														</ul>
													</div>
												</>
											</Tab>
										);
									})}
								</Tabs>
							)}
						</div>
						{logos?.length > 0 && (
							<div className="collection-logos">
								{logos.map((logo, index) => {
									return (
										<Image key={index} {...logo}></Image>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
			{specifications?.length > 0 && (
				<div className="product-carousel-showcase--specifications">
					<div className="specifications-row">
						<a
							href="#"
							onClick={toggleSpecs}
							className="specification-button-toggle"
						>
							<div className="specifications-icon-indicator">
								<div></div>
								<div></div>
							</div>
							<span className="h5">{specificationTitle}</span>
						</a>

						<div className="specifications-row--results">
							<ul className="specifications-row--listing">
								{specifications.map((measurement, key) => {
									return (
										<li key={key}>
											<span className="measurement">
												{measurement.measurement}
											</span>
											<span className="specification">
												{measurement.spec}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CollectionCarousels;
