import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IFlorzLandingPageProps extends IBaseElementProps {
	children?: any;
	theme?: 'Default' | 'SmartDrop' | 'LivingWood' | 'FLORZ';
}
/**
 * Florz Landing Page
 *
 * The mandatory shell for any Florz Landing Page.
 * Use this component to wrap all other Florz components.
 *
 * @icon buddicons-forums
 * @block
 */
const FlorzLandingPage: React.FC<IFlorzLandingPageProps> = ({
	children,
	theme = 'Default',
}) => {
	return (
		<div
			className={cn(
				'florz-landing-page intro-revealed',
				theme && theme.replace(/\s+/g, '-').toLowerCase(),
			)}
		>
			{children}
		</div>
	);
};

export default FlorzLandingPage;
