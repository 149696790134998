import AdvancedImage from './src/components/AdvancedImage/index';
import AlternatingTextImage from './src/components/AlternatingTextImage/index';
import Banner from './src/components/Banner/index';
import ButtonList from './src/components/Button/ButtonList';
import Button from './src/components/Button/index';
import CollectionCarousels from './src/components/CollectionCarousels/index';
import CollectionIntro from './src/components/CollectionIntro/index';
import FlorzHero from './src/components/FlorzHero/index';
import FlorzInfo from './src/components/FlorzInfo/index';
import FlorzLandingPage from './src/components/FlorzLandingPage/index';
import Footer from './src/components/Footer/index';
import FormTabs from './src/components/FormTabs/index';
import Header from './src/components/Header/index';
import InfoBanner from './src/components/InfoBanner/index';
import LandingPage from './src/components/LandingPage/index';
import MaskedDoorImage from './src/components/MaskedDoorImage/index';
import Spacer from './src/components/Spacer/index';
import Container from './node_modules/tbk-components/src/components/Container/index.js';
import RawHTML from './node_modules/tbk-components/src/components/RawHTML/index.js';

const components = {
	AdvancedImage,
	AlternatingTextImage,
	Banner,
	ButtonList,
	Button,
	CollectionCarousels,
	CollectionIntro,
	FlorzHero,
	FlorzInfo,
	FlorzLandingPage,
	Footer,
	FormTabs,
	Header,
	InfoBanner,
	LandingPage,
	MaskedDoorImage,
	Spacer,
	Container,
	RawHTML
};

export default components;
