import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface ISpacerProps extends IBaseElementProps {
	/**
	 * Spacer Size
	 */
	size: 'Default' | 'Small' | 'Medium' | 'Large' | 'X-Large';
	id?: string;
}

/**
 * Spacer
 * @block
 * @icon move
 */
const Spacer: React.FC<ISpacerProps> = ({
	size = 'Default',
	className,
	classNames = [],
	id,
}) => (
	<div
		id={id}
		className={
			className ||
			cn(
				'florz-spacer',

				size === 'Small' && 'florz-spacer--small',
				(size === 'Medium' || size === 'Default') &&
					'florz-spacer--medium',
				size === 'Large' && 'florz-spacer--large',
				size === 'X-Large' && 'florz-spacer--x-large',
				...classNames,
			)
		}
	></div>
);

export default Spacer;
